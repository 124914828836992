/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/**
 * конфигурация таблицы StatClientsTable.vue
*/

/**
 * генератор колонок таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/column-options.html#label
*/

import {
  getSelectedRowValue
} from './utils';

function generateTableColumns({ i18nInstance, hiddenColumnRules, additionalRules }) {
  const $t = i18nInstance.t.bind(i18nInstance);

  return [
    {
      label: 'MAC',
      field: 'mac',
      width: 'auto',
      type: 'text',
      hidden: hiddenColumnRules.mac
    },
    {
      label: $t('statistics.username'),
      field: 'lastAuth',
      width: '15%',
      type: 'text',
      hidden: hiddenColumnRules.lastAuth
    },
    {
      label: $t('general.info'),
      field: 'clientsInfo',
      width: 'auto',
      type: 'text',
      sortable: false,
      hidden: hiddenColumnRules.clientsInfo
    },
    {
      label: $t('general.trafficRx'),
      field: 'trafficRX',
      width: 'auto',
      type: 'text',
      sortFn: (x, y, col, rowX, rowY) => {
        // x - row1 value for column
        // y - row2 value for column
        // col - column being sorted
        // rowX - row object for row1
        // rowY - row object for row2

        const rowX_trafficRX = rowX.trafficRX;
        const rowY_trafficRX = rowY.trafficRX;

        if (rowX_trafficRX < rowY_trafficRX) {
          return -1;
        }

        if (rowX_trafficRX > rowY_trafficRX) {
          return 1;
        }

        return 0;
      },
      hidden: hiddenColumnRules.trafficRX
    },
    {
      label: $t('general.trafficTx'),
      field: 'trafficTX',
      width: 'auto',
      type: 'text',
      sortFn: (x, y, col, rowX, rowY) => {
        // x - row1 value for column
        // y - row2 value for column
        // col - column being sorted
        // rowX - row object for row1
        // rowY - row object for row2

        const rowX_trafficTX = rowX.trafficTX;
        const rowY_trafficTX = rowY.trafficTX;

        if (rowX_trafficTX < rowY_trafficTX) {
          return -1;
        }

        if (rowX_trafficTX > rowY_trafficTX) {
          return 1;
        }

        return 0;
      },
      hidden: hiddenColumnRules.trafficTX
    },
    {
      label: $t('general.sessions'),
      field: 'sessions',
      width: '25%',
      type: 'text',
      sortable: true,
      sortFn: (x, y, col, rowX, rowY) => {
        // x - row1 value for column
        // y - row2 value for column
        // col - column being sorted
        // rowX - row object for row1
        // rowY - row object for row2

        const rowX_sessionDuration = rowX.sessions;
        const rowY_sessionDuration = rowY.sessions;

        if (rowX_sessionDuration < rowY_sessionDuration) {
          return -1;
        }

        if (rowX_sessionDuration > rowY_sessionDuration) {
          return 1;
        }

        return 0;
      },
      hidden: hiddenColumnRules.sessions
      // visibilityDropdownSettings: {
      //   hidden: !areLocationsVisible
      // }
    }
  ];
}

/**
 * генератор строк таблицы
 * https://xaksis.github.io/vue-good-table/guide/configuration/#rows
 * https://xaksis.github.io/vue-good-table/guide/advanced/grouped-table.html#customizing-header-row
*/
function generateTableRows({ clientsData, selectedRowClientsIds }) {
  return clientsData.map(client => {
    const {
      Type,
      data,
      duration,
      id,
      mac_addr,
      offPeriod,
      rx_bytes,
      session_count,
      sessions,
      tx_bytes,
      username,
      usernames
    } = client;

    return {
      // nativeData - хранит неформатированные данные. Нужны, чтобы удобно эмитить их по событию для дальнейших манипуляций.
      nativeData: client,
      mac: id,
      lastAuth: usernames,
      clientsInfo: id,
      trafficRX: rx_bytes,
      trafficTX: tx_bytes,
      sessions: duration,
      // можно ли выбирать строку чекбоксом
      // vgtDisabled: false,
      // выбрана ли строка в чекбоксе исходя из стейта, который контролирует выбранные строки
      vgtSelected: getSelectedRowValue(id, selectedRowClientsIds)
    };
  });
}

export {
  generateTableColumns,
  generateTableRows
};
