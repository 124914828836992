<template>
  <span>
    {{ formattedBytes ? formattedBytes : '-' }}
  </span>
</template>

<script>
/**
 * компонент - ячейка trafficRX, trafficTX.
 * компонент локальный: используется только в StatClientsTable.vue
 * @component
 */

import commonService from '@/services/commonService';

export default {
  name: 'TrafficCell',
  components: {},
  props: {
    bytes: {
      type: Number,
      required: true
    }
  },
  computed: {
    formattedBytes() {
      return commonService.formatBytesWithI18N(this.bytes);
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
</style>
