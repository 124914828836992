<template>
  <div :style="{'z-index': zIndex}" class="mulstiselect-wrapper-for-small-size-component">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MultiselectWrapperForUseInFilters',
  props: {
    zIndex: {
      default: undefined
    }
  }
};
</script>

<style>
.mulstiselect-wrapper-for-small-size-component {
  width: 100%;
}

.mulstiselect-wrapper-for-small-size-component .multiselect__select {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 27px !important;
  height: 22px !important;
  align-items: center;
}
.mulstiselect-wrapper-for-small-size-component .multiselect__tags {
  padding-top: 0px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 27px !important;
  height: 22px !important;
  align-items: center;
  font-size: 14px !important;
  /*text-overflow: ellipsis !important;*/
}

.mulstiselect-wrapper-for-small-size-component .multiselect__tag {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  background-color: rgba(130, 130, 130, 0.15) !important;
  color: inherit !important;
}

.mulstiselect-wrapper-for-small-size-component .multiselect__tags > span {
  max-width: 100% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mulstiselect-wrapper-for-small-size-component .multiselect__tag-icon {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  line-height: 15px !important;
}

.mulstiselect-wrapper-for-small-size-component .multiselect__control {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 27px !important;
  align-items: center;
}

.mulstiselect-wrapper-for-small-size-component .multiselect__input {
  min-height: 27px !important;
  margin-bottom: 0px !important;
}

.mulstiselect-wrapper-for-small-size-component .multiselect__element {
  margin-bottom: 0px;
  height: 22px !important;
  min-height: 22px !important;
  max-height: 22px !important;
}
.mulstiselect-wrapper-for-small-size-component .multiselect__option {
  margin: 0px !important;
  height: 22px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  min-height: 22px !important;
  max-height: 22px !important;
}

.mulstiselect-wrapper-for-small-size-component .multiselect__option--highlight {
  margin: 0px !important;
  height: 22px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  min-height: 22px !important;
  max-height: 22px !important;
  /*background-color: rgba(130, 130, 130, 0.04) !important;*/
  /*color: inherit !important*/
}

.mulstiselect-wrapper-for-small-size-component .multiselect__option::after {
  margin: 0px !important;
  height: 22px !important;
  border: 0px !important;
  border-top: 0px !important;
  border-bottom: 0px !important;
  min-height: 22px !important;
  max-height: 22px !important;
  line-height: 22px !important;
  /*display: none;*/
}

.mulstiselect-wrapper-for-small-size-component .multiselect__content-wrapper {
  overflow-x: hidden !important;
  min-width: 300px;
  width: fit-content !important;
  text-align: left;
  /*    overflow-x: hidden;*/
  /*text-overflow: ellipsis;*/
}
</style>
