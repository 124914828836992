<template>
  <div v-if="props.row.sessions">
    <div class="sessions-cell-caption-container">
      <small class>{{ props.row.sessions | momentnow(isRuLocale) }}</small>
      <small class="sessions-client-duration-padding">
        {{ (fullPeriod - props.row.sessions) | momentnow(isRuLocale) }}
      </small>
    </div>

    <div class="progress progress-xs">
      <progressbar
        role="progressbar"
        :now="percentData"
        class="progress-bar bg-success"
      />
      <progressbar
        role="progressbar"
        :now="100 - percentData"
        class="progress-bar bg-default"
      />
    </div>

    <div class="sessions-cell-caption-container">
      <small class="text-success">{{ percentData }}%</small>
      <small class="text-muted">
        {{ (100 - percentData) }}%
      </small>
    </div>
  </div>
  <span v-else>-</span>
</template>

<script>
/**
 * компонент - ячейка sessions.
 * компонент локальный: используется только в StatClientsTable.vue
 * @component
 */

import { createNamespacedHelpers } from 'vuex';

import { progressbar } from 'vue-strap';

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');

export default {
  name: 'SessionsCell',
  components: {
    progressbar
  },
  props: {
    props: {
      type: Object,
      required: true
    },
    timeSlice: {
      type: Object,
      required: true
    }
  },
  filters: {
    momentnow(value, isRuLocale) {
      if (isRuLocale) {
        return moment.duration(value, 'seconds').format('D дн., h ч., mm мин., ss сек.');
      }
      return moment.duration(value, 'seconds').format('D [day] h [h], mm [min], ss [sec]');
    }
  },
  computed: {
    ...localeMapGetters(['isRuLocale']),
    fullPeriod() {
      const periodStart = parseInt(this.timeSlice.startDate, 10) / 1000;
      let periodStop = parseInt(this.timeSlice.endDate, 10) / 1000;
      if (periodStop * 1000 > Date.now()) {
        periodStop = Date.now() / 1000;
      }
      return periodStop - periodStart;
    },
    percentData() {
      const percentData = (100 * this.props.row.sessions) / this.fullPeriod;

      if (percentData < 0.1 && percentData !== 0) {
        return 0.1;
      }
      if (percentData < 0) {
        return 0;
      }
      if (percentData > 100) {
        return 100;
      }
      return percentData.toFixed(1);
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.sessions-cell-caption-container {
  display: flex;
  justify-content: space-between;
}

.sessions-client-duration-padding {
  padding-left: 0.7em;
}
</style>
