<template>
  <WTable
    style="height: calc(70vh)"
    max-height="calc(70vh - 100px)"
    :columns="columns"
    :rows="rows"
    :isDisabled="isDisabled"
    :bordered="false"
    :withToggleColumnVisibilityMode="!isOneColMode"
    :line-numbers="!isOneColMode"
    :row-style-class="rowStyleClassFn"
    :sort-options="{
      initialSortBy: {field: 'trafficRX', type: 'desc'},
      enabled: true,
    }"
    :search-options="{
      enabled: true,
      skipDiacritics: true,
      placeholder: `${$t('general.search')}`,
      externalQuery: searchQuery,
      searchFn: searchFn
    }"
    :pagination-options="{
      enabled: false, // isOneColMode // info: отключена из-за неовместимости с легаси
      remoteMode: true,
      mode: 'pages', // pages, records
      position: 'bottom',
      perPage: 10,
      perPageDropdownEnabled: true,
      perPageDropdown: [5, 10, 20, 40, 50],
      dropdownAllowAll: true,
      jumpFirstOrLast : true,
      firstLabel: $t('general.pagination.firstPage'),
      lastLabel: $t('general.pagination.lastPage'),
      nextLabel: $t('general.pagination.nextPage'),
      prevLabel: $t('general.pagination.prevPage'),
      rowsPerPageLabel: $t('general.pagination.rowsPerPage'),
      pageLabel: $t('general.pagination.page'),
      ofLabel: $t('general.pagination.of'),
      allLabel: $t('general.pagination.all')
    }"
    :isLoading.sync="isDataLoading"
    @update:columns="handleUpdateColumns"
    @on-selected-rows-change="handleSelectedRowsChange"
    @on-select-all="handleSelectedAllRowsChange"
    @on-cell-click="handleCellClick"
    @on-page-change="handlePageChange"
    @on-per-page-change="handlePerPageChange"
    @on-sort-change="onSortChange"
  >

    <template v-slot:table-menu>
      <slot name="table-menu"></slot>
    </template>

    <template v-slot:table-row="props">
      <template v-if="props.column.field === 'mac'">
        <MacCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'lastAuth'">
        <LastAuthCell :props="props" />
      </template>

      <template v-else-if="props.column.field === 'clientsInfo'">
        <ClientsInfoCell
          :props="props"
          @on-client-info-click="handleClientInfoClick(props.row.clientsInfo)"
        />
      </template>

      <template v-else-if="props.column.field === 'trafficRX'">
        <TrafficCell :bytes="props.row.trafficRX" />
      </template>

      <template v-else-if="props.column.field === 'trafficTX'">
        <TrafficCell :bytes="props.row.trafficTX" />
      </template>

      <template v-else-if="props.column.field === 'sessions'">
        <SessionsCell :props="props" :timeSlice="timeSlice"/>
      </template>

      <template v-else>
        {{ props.formattedRow[props.column.field] ? props.formattedRow[props.column.field] : '-' }}
      </template>
    </template>

    <template v-slot:table-actions-bottom>
      <slot name="table-actions-bottom"></slot>
    </template>

  </WTable>
</template>

<script>
/**
 * компонент - таблица статистики по clients.
 * компонент локальный: используется только в Statclients.vue
 * подробнее по настройкам и пропсам - см компонент WTable.vue
 * @component
*/

import {
  generateTableColumns,
  generateTableRows
} from './config';

import {
  MacCell,
  LastAuthCell,
  ClientsInfoCell,
  TrafficCell,
  SessionsCell
} from './components';

export default {
  name: 'StatClientsTable',
  components: {
    MacCell,
    LastAuthCell,
    ClientsInfoCell,
    TrafficCell,
    SessionsCell
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    searchQuery: {
      type: String,
      default: ''
    },
    searchFn: {
      type: Function,
      required: true
    },
    activeRowClientId: {
      type: String,
      default: ''
    },
    selectedRowClientsIds: {
      type: Array,
      required: true
    },
    timeSlice: {
      type: Object,
      required: true
    },
    isDataLoading: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isOneColMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTableColumnHidden: {
        mac: false,
        lastAuth: false,
        clientsInfo: false,
        trafficRX: false,
        trafficTX: false,
        sessions: false
      }
    };
  },
  watch: {
  },
  computed: {
    columns: {
      get() {
        return generateTableColumns({
          i18nInstance: this.$i18n,
          hiddenColumnRules: {
            mac: this.isTableColumnHidden.mac,
            lastAuth: this.isTableColumnHidden.lastAuth || this.isOneColMode,
            clientsInfo: this.isTableColumnHidden.clientsInfo || (this.isOneColMode && this.$can('read', { route: 'Clients' })),
            trafficRX: this.isTableColumnHidden.trafficRX || this.isOneColMode,
            trafficTX: this.isTableColumnHidden.trafficTX || this.isOneColMode,
            sessions: this.isTableColumnHidden.sessions || this.isOneColMode
          },
          additionalRules: {}
        });
      },
      set(updatedColumns) {
        // обновить таблицу, когда обновились данные в колонках
        // например, какую-то колонку скрыли/раскрыли
        updatedColumns.forEach(el => {
          this.isTableColumnHidden[el.field] = el.hidden;
        });
      }
    },
    rows() {
      return generateTableRows({
        clientsData: this.items,
        selectedRowClientsIds: this.selectedRowClientsIds
      });
    }
  },
  methods: {
    handleUpdateColumns(columns) {
      this.columns = columns;
    },
    rowStyleClassFn(row) {
      const rowCpeId = row.nativeData.id;

      return this.activeRowClientId === rowCpeId ? 'stat-clients-table__active-row' : '';
    },
    handleSelectedAllRowsChange(event) {
      this.$emit('on-select-all', event);
    },
    handleSelectedRowsChange(event) {
      const { selectedRows } = event;
      this.$emit('on-selected-rows-change', selectedRows);
    },
    handleCellClick(event) {
      this.$emit('on-cell-click', event.row);
    },
    handlePageChange(event) {
      this.$emit('on-page-change', event);
    },
    handlePerPageChange(event) {
      this.$emit('on-per-page-change', event);
    },
    onSortChange(event) {
      this.$emit('on-sort-change', event);
    },
    handleClientInfoClick(id) {
      this.$emit('on-client-info-click', id);
    }
  },
  created() {
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
</style>

<style lang="css">
.stat-clients-table__active-row {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent);
  color: var(--font-color-main);
}

.stat-clients-table__active-row:hover {
  background: color-mix(in srgb, var(--brand-success) 70%, transparent) !important;
  color: var(--font-color-main) !important;
}
</style>
