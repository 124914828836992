import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import helpers from '../helpers';
import store from '../store';
import wlanService from './wlanService';
import cpeService from './cpeService';
import { API_URL } from '@/config';

function getDateForUTC(dateObject) {
  // console.log(dateObject);
  return [
    dateObject.getFullYear(),
    dateObject.getMonth(),
    dateObject.getDate(),
    dateObject.getHours(),
    dateObject.getMinutes(),
    dateObject.getSeconds()
  ];
}

export default {
  getClientsSessions(ctx) {
    ctx.$store.commit('toggleLoadingClientsRating');
    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();
    ctx.disableStatMode();
    Vue.axios
      .post(`${API_URL || ''}/api/stat/session/clients`, {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        timebounds: { start, stop },
        q: ctx.formattedQ,
        sort_by: ctx.filters.sort_by,
        page: ctx.filters.page
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            /* Rating List Section */
            const sessionList = response.data.data.itemslist;
            ctx.$store.commit('setClientsRatingList', sessionList);

            /* Session List Section */
            ctx.$store.commit('setClientsSessionsList', sessionList);

            // cpeService.getAllCompactCpes(ctx);
            cpeService.getAllCompactCpesWithDataRemaining(ctx)
            // wlanService.getAllWlans(ctx);
            wlanService.getAllCompactWlansWithDataRemainingWithoutClients(ctx);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleLoadingClientsRating');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleLoadingClientsRating');
        }
      );
  },

  getClientsSessionsWithLocationFilter(ctx) {
    let locationForQ;
    let withChilds = ctx.filters.with_childs;
    if (ctx.filters.location === 'All locations') {
      locationForQ = ctx.userBaseLocation;
      withChilds = true;
      ctx.filters.with_childs = true;
    } else {
      locationForQ = ctx.filters.location;
    }

    ctx.$store.commit('toggleLoadingClientsRating');
    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();
    ctx.disableStatMode();
    Vue.axios
      .post(`${API_URL || ''}/api/stat/session/clients`, {
        action: 'R',
        timebounds: { start, stop },
        q: ctx.formattedQ,
        sort_by: ctx.filters.sort_by,
        page: ctx.filters.page,
        location: locationForQ,
        with_childs: withChilds
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            /* Rating List Section */
            const sessionList = response.data.data.itemslist;
            ctx.$store.commit('setClientsRatingList', sessionList);

            /* Session List Section */
            ctx.$store.commit('setClientsSessionsList', sessionList);

            // cpeService.getAllCompactCpes(ctx);
            cpeService.getAllCompactCpesWithDataRemaining(ctx)
            // wlanService.getAllWlans(ctx);
            wlanService.getAllCompactWlansWithDataRemainingWithoutClients(ctx);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleLoadingClientsRating');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleLoadingClientsRating');
        }
      );
  },

  getClientsSessionsWithLocationFilterWithPagination(ctx) {
    let locationForQ;
    let withChilds = ctx.filters.with_childs;
    if (ctx.filters.location === 'All locations') {
      locationForQ = ctx.userBaseLocation;
      withChilds = true;
      ctx.filters.with_childs = true;
    } else {
      locationForQ = ctx.filters.location;
    }

    ctx.$store.commit('toggleLoadingClientsRating');
    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();
    ctx.disableStatMode();
    Vue.axios
      .post(`${API_URL || ''}/api/stat/session/clients`, {
        action: 'R',
        timebounds: {start, stop},
        q: ctx.formattedQ,
        sort_by: ctx.filters.sort_by,
        page: ctx.filters.page,
        location: locationForQ,
        with_childs: withChilds
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            /* Rating List Section */
            const sessionList = response.data.data.itemslist;
            ctx.$store.commit('pushClientsRatingList', sessionList);

            /* Session List Section */
            ctx.$store.commit('pushClientsSessionsList', sessionList);

            const totalResult = response.data.data.total;
            ctx.totalResult = totalResult;
            // console.log(totalResult)

            if (ctx.$store.state.clientsSessionsList.length >= totalResult) {
              // console.log('case canLoadMore = false ')
              ctx.canLoadMore = false;
            } else {
              // console.log('case canLoadMore = true ')
              ctx.canLoadMore = true;
            }

            // cpeService.getAllCompactCpes(ctx);
            cpeService.getAllCompactCpesWithDataRemaining(ctx)
            // wlanService.getAllWlans(ctx);
            wlanService.getAllCompactWlansWithDataRemainingWithoutClients(ctx);
          } else if (response.data.status === 'error') {
            VueNotifications.error({message: response.data.description});
          }
          ctx.$store.commit('toggleLoadingClientsRating');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({message: err});
          }

          ctx.error = err;
          ctx.$store.commit('toggleLoadingClientsRating');
        }
      );
  },

  getClientStat(ctx) {
    // Получаем статистику по конкретному CLient зв определенный период времени
    ctx.$store.commit('toggleLoadingStats');
    if (!ctx.drPickerDateForStat.startDate || !ctx.drPickerDateForStat.endDate) {
      ctx.drPickerDateForStat.startDate = ctx.drPickerDateForRating.startDate;
      ctx.drPickerDateForStat.endDate = ctx.drPickerDateForRating.endDate;
    }
    const start = moment(ctx.drPickerDateForStat.startDate).unix();
    const stop = moment(ctx.drPickerDateForStat.endDate).unix();
    let apiLink;
    let statQuery;
    if (ctx.filters.selectedWLAN === '') {
      apiLink = '/api/stat/timeline/clients';
      statQuery = {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        items: { [ctx.clientSelectedMacAddr]: {} },
        timebounds: { start, stop }
      };
    } else {
      apiLink = '/api/stat/timeline/wlans/clients';
      statQuery = {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        ids: [ctx.filters.selectedWLAN],
        items: { [ctx.clientSelectedMacAddr]: {} },
        timebounds: { start, stop }
      };
    }
    // Обнуляю данные графиков
    ctx.refreshCharts();

    Vue.axios.post(`${API_URL || ''}${apiLink}`, statQuery).then(
      (response) => {
        if (response.data.status === 'success') {
          const periodStart = ctx.drPickerDateForStat.startDate / 1000;
          let periodStop = ctx.drPickerDateForStat.endDate / 1000;
          if (periodStop * 1000 > Date.now()) {
            periodStop = Date.now() / 1000;
          }
          const minXAxis = new Date(periodStart * 1000);
          const maxXAxis = new Date(periodStop * 1000);
          ctx.txRxChart.xAxis.min = Date.UTC(...getDateForUTC(minXAxis));
          ctx.txRxChart.xAxis.max = Date.UTC(...getDateForUTC(maxXAxis));
          ctx.txRxRateChart.xAxis.min = Date.UTC(...getDateForUTC(minXAxis));
          ctx.txRxRateChart.xAxis.max = Date.UTC(...getDateForUTC(maxXAxis));

          ctx.currentClientStats = response.data.data.itemslist;
          if (ctx.currentClientStats.length === 0) {
            ctx.$store.commit('toggleLoadingStats');
            return;
          }
          // Сортирую массив статистики по времени
          ctx.$store.commit('toggleLoadingStats');
          const sortedClientStats = ctx.currentClientStats.sort((curr, prev) => {
            if (curr.timestamp > prev.timestamp) {
              return 1;
            }
            if (curr.timestamp < prev.timestamp) {
              return -1;
            }
            return 0;
          });
          // Формирую данные для HC
          sortedClientStats.forEach((item) => {
            if (item.timestamp == 0) {
              return;
            }
            const time = new Date(item.timestamp * 1000);
            if (item.cpu_load > 1) {
              item.cpu_load = 1;
            }

            // Data for TxRx
            ctx.txRxChart.series[0].data.push({
              x: Date.UTC(...getDateForUTC(time)),
              y: parseFloat((item.traffic_tx / 1000 / 1000).toFixed(2))
            });
            ctx.txRxChart.series[1].data.push({
              x: Date.UTC(...getDateForUTC(time)),
              y: parseFloat((item.traffic_rx / 1000 / 1000).toFixed(2))
            });
            ctx.txRxChart.series[2].data.push({
              x: Date.UTC(...getDateForUTC(time)),
              y: parseFloat(
                (
                  parseFloat((item.traffic_tx / 1000 / 1000).toFixed(2)) +
                  parseFloat((item.traffic_rx / 1000 / 1000).toFixed(2))
                ).toFixed(2)
              )
            });

            // Data for Rate TxRx
            ctx.txRxRateChart.series[0].data.push({
              x: Date.UTC(...getDateForUTC(time)),
              y: parseFloat((item.traffic_tx_rate / 1000 / 1000).toFixed(4))
            });
            ctx.txRxRateChart.series[1].data.push({
              x: Date.UTC(...getDateForUTC(time)),
              y: parseFloat((item.traffic_rx_rate / 1000 / 1000).toFixed(4))
            });
            ctx.txRxRateChart.series[2].data.push({
              x: Date.UTC(...getDateForUTC(time)),
              y:
                parseFloat((item.traffic_tx_rate / 1000 / 1000).toFixed(4)) +
                parseFloat((item.traffic_rx_rate / 1000 / 1000).toFixed(4))
            });
          });
        } else if (response.data.status === 'error') {
          ctx.refreshCharts();
          VueNotifications.error({ message: response.data.description });
          ctx.$store.commit('toggleLoadingStats');
        }
      },
      (err) => {
        // VueNotifications.error({ message: err });

        // show errors in the console instead of the alert window
        if (
          Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
          typeof window.isShowAxiosErrorInConsole === 'function' &&
          window.isShowAxiosErrorInConsole(err)
        ) {
          window.showAxiosErrInConsole(err);
        } else {
          VueNotifications.error({ message: err });
        }

        ctx.error = err;
        ctx.$store.commit('toggleLoadingStats');
      }
    );
  },

  getClientSession(ctx) {
    const start = moment(ctx.drPickerDateForStat.startDate).unix();
    const stop = moment(ctx.drPickerDateForStat.endDate).unix();
    Vue.axios
      .post(`${API_URL || ''}/api/stat/session/clients`, {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        timebounds: { start, stop },
        items: { [ctx.clientSelectedMacAddr]: {} }
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            ctx.$store.commit('setCpesSessionsList', response.data.data.itemslist);
            // if(ctx.statCpeId){
            //     ctx.responseStats(this);
            // }

            ctx.sessionChart.series[0].data = [];
            ctx.sessionChart.series[1].data = [];

            let sortedClientSession = [];
            const periodStart = ctx.drPickerDateForStat.startDate / 1000;
            let periodStop = ctx.drPickerDateForStat.endDate / 1000;

            if (periodStop * 1000 > Date.now()) {
              periodStop = Date.now() / 1000;
            }

            // Выставляем границы графика по оси X
            const minXAxis = new Date(periodStart * 1000);
            const maxXAxis = new Date(periodStop * 1000);
            ctx.sessionChart.xAxis.min = Date.UTC(...getDateForUTC(minXAxis));
            ctx.sessionChart.xAxis.max = Date.UTC(...getDateForUTC(maxXAxis));

            const clientSession = response.data.data.itemslist[0];

            if (clientSession && clientSession.sessions) {
              sortedClientSession = clientSession.sessions.sort((curr, prev) => {
                if (curr.start > prev.start) {
                  return 1;
                }
                if (curr.start < prev.start) {
                  return -1;
                }
                return 0;
              });
            } else {
              return;
            }

            sortedClientSession.forEach((item, i) => {
              let start = new Date(item.start * 1000);
              let stop = new Date(item.stop * 1000);
              if (item.start < periodStart && (item.stop === 0 || item.stop > periodStop)) {
                start = periodStart;
                stop = periodStop;
                start = new Date(start * 1000);
                stop = new Date(stop * 1000);
                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(start)),
                  y: 1,
                  freq: item.freq,
                  cpe: item.cpe_id,
                  wlan: item.wlan_id,
                  mode: item.mode
                });
                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(stop)),
                  y: 1,
                  freq: item.freq,
                  cpe: item.cpe_id,
                  wlan: item.wlan_id,
                  mode: item.mode
                });
              } else if (item.start > periodStart && (item.stop > periodStop || item.stop === 0)) {
                stop = periodStop;
                stop = new Date(stop * 1000);
                let offStart = periodStart;
                offStart = new Date(offStart * 1000);
                if (sortedClientSession[i - 1]) {
                  const prevStop = new Date(sortedClientSession[i - 1].stop * 1000);
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(prevStop)),
                    y: null
                  });
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(prevStop)),
                    y: 0
                  });
                } else {
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(offStart)),
                    y: 0
                  });
                }
                ctx.sessionChart.series[1].data.push({
                  x: Date.UTC(...getDateForUTC(start)),
                  y: 0.5
                });
                ctx.sessionChart.series[1].data.push({
                  x: Date.UTC(...getDateForUTC(start)),
                  y: null
                });

                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(start)),
                  y: null
                });
                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(start)),
                  y: 1,
                  freq: item.freq,
                  cpe: item.cpe_id,
                  wlan: item.wlan_id,
                  mode: item.mode
                });
                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(stop)),
                  y: 1,
                  freq: item.freq,
                  cpe: item.cpe_id,
                  wlan: item.wlan_id,
                  mode: item.mode
                });
              } else if (item.start < periodStart && item.stop < periodStop) {
                start = periodStart;
                start = new Date(start * 1000);

                let offStop = periodStop;
                offStop = new Date(offStop * 1000);
                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(start)),
                  y: 1,
                  freq: item.freq,
                  cpe: item.cpe_id,
                  wlan: item.wlan_id,
                  mode: item.mode
                });
                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(stop)),
                  y: 1,
                  freq: item.freq,
                  cpe: item.cpe_id,
                  wlan: item.wlan_id,
                  mode: item.mode
                });
                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(stop)),
                  y: null
                });

                ctx.sessionChart.series[1].data.push({
                  x: Date.UTC(...getDateForUTC(stop)),
                  y: null
                });
                ctx.sessionChart.series[1].data.push({
                  x: Date.UTC(...getDateForUTC(stop)),
                  y: 0
                });
                if (sortedClientSession[i + 1]) {
                  const nextStart = new Date(sortedClientSession[i + 1].start * 1000);
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(nextStart)),
                    y: 0.5
                  });
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(nextStart)),
                    y: null
                  });
                } else {
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(offStop)),
                    y: 0.5
                  });
                }
              } else if (item.start > periodStart && item.stop < periodStop) {
                if (sortedClientSession[i - 1]) {
                  const prevStop = new Date(sortedClientSession[i - 1].stop * 1000);
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(prevStop)),
                    y: null
                  });
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(prevStop)),
                    y: 0.5
                  });
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(start)),
                    y: 0.5
                  });
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(start)),
                    y: null
                  });
                } else {
                  let offStart = periodStart;
                  offStart = new Date(offStart * 1000);
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(offStart)),
                    y: 0.5
                  });
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(start)),
                    y: 0.5
                  });
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(start)),
                    y: null
                  });
                }

                if (sortedClientSession[i + 1]) {
                  const nextStart = new Date(sortedClientSession[i + 1].start * 1000);
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(stop)),
                    y: null
                  });
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(stop)),
                    y: 0.5
                  });
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(nextStart)),
                    y: 0.5
                  });
                } else {
                  let offStop = periodStop;
                  offStop = new Date(offStop * 1000);
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(stop)),
                    y: null
                  });
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(stop)),
                    y: 0.5
                  });
                  ctx.sessionChart.series[1].data.push({
                    x: Date.UTC(...getDateForUTC(offStop)),
                    y: 0.5
                  });
                }

                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(start)),
                  y: null
                });
                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(start)),
                  y: 1,
                  freq: item.freq,
                  cpe: item.cpe_id,
                  wlan: item.wlan_id,
                  mode: item.mode
                });
                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(stop)),
                  y: 1,
                  freq: item.freq,
                  cpe: item.cpe_id,
                  wlan: item.wlan_id,
                  mode: item.mode
                });
                ctx.sessionChart.series[0].data.push({
                  x: Date.UTC(...getDateForUTC(stop)),
                  y: null
                });
              }
            });
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
        }
      );
  },

  getClientsRaw(ctx) {
    ctx.$store.commit('toggleLoadingClientsRaw');
    ctx.$store.commit('setClientsRawList', false);
    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();
    Vue.axios
      .post(`${API_URL || ''}/api/stat/session/clients_raw`, {
        action: 'R',
        location: ctx.$store.getters.getDefaultLocationForRequests(ctx), with_childs: ctx.$store.getters.getDefaultWithChildsForRequests(ctx),
        timebounds: { start, stop },
        q: ctx.formattedQ,
        sort_by: ctx.filters.sort_by,
        page: ctx.filters.page
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            const list = response.data.data.itemslist;
            ctx.$store.commit('setClientsRawList', list);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleLoadingClientsRaw');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleLoadingClientsRaw');
        }
      );
  },

  getClientsRawWithLocationFilter(ctx) {
    let locationForQ;
    let withChilds = ctx.filters.with_childs;
    if (ctx.filters.location === 'All locations') {
      locationForQ = ctx.userBaseLocation;
      withChilds = true;
      ctx.filters.with_childs = true;
    } else {
      locationForQ = ctx.filters.location;
    }
    ctx.$store.commit('toggleLoadingClientsRaw');
    ctx.$store.commit('setClientsRawList', false);
    const start = moment(ctx.drPickerDateForRating.startDate).unix();
    const stop = moment(ctx.drPickerDateForRating.endDate).unix();
    Vue.axios
      .post(`${API_URL || ''}/api/stat/session/clients_raw`, {
        action: 'R',
        timebounds: { start, stop },
        q: ctx.formattedQ,
        sort_by: ctx.filters.sort_by,
        page: ctx.filters.page,
        location: locationForQ,
        with_childs: withChilds
      })
      .then(
        (response) => {
          if (response.data.status === 'success') {
            const list = response.data.data.itemslist;
            ctx.$store.commit('setClientsRawList', list);
          } else if (response.data.status === 'error') {
            VueNotifications.error({ message: response.data.description });
          }
          ctx.$store.commit('toggleLoadingClientsRaw');
        },
        (err) => {
          // VueNotifications.error({ message: err });

          // show errors in the console instead of the alert window
          if (
            Object.prototype.hasOwnProperty.call(window, 'isShowAxiosErrorInConsole') &&
            typeof window.isShowAxiosErrorInConsole === 'function' &&
            window.isShowAxiosErrorInConsole(err)
          ) {
            window.showAxiosErrInConsole(err);
          } else {
            VueNotifications.error({ message: err });
          }

          ctx.error = err;
          ctx.$store.commit('toggleLoadingClientsRaw');
        }
      );
  }
};
