<template>
  <span>
    {{ lastUsername ? lastUsername : '-' }}
  </span>
</template>

<script>
/**
 * компонент - ячейка lastAuth.
 * компонент локальный: используется только в StatClientsTable.vue
 * @component
 */

export default {
  name: 'LastAuthCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    usernames() {
      if (this.props.row.lastAuth === undefined) {
        return [];
      }
      return this.props.row.lastAuth;
    },
    lastUsername() {
      return this.usernames.length > 0 ? this.usernames[this.usernames.length - 1] : undefined;
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
</style>
