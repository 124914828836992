<template>
  <WButton link sm
    style="text-wrap: wrap; text-align: left;"
    @click.prevent.stop="handleLinkClick"
  >
    {{ $t('statistics.showClient') }}
  </WButton>
</template>

<script>
/**
 * компонент - ячейка clientsInfo.
 * компонент локальный: используется только в StatClientsTable.vue
 * @component
 */

export default {
  name: 'ClientsInfoCell',
  components: {},
  props: {
  },
  methods: {
    handleLinkClick(event) {
      this.$emit('on-client-info-click', event);
    }
  }
};
</script>

<style lang="css" scoped>
</style>
