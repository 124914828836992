<template>
  <div
    class="mac-cell">
    <i class="fa"
      :class="{
        'fa-plug': props.row.nativeData.Type === 'wired',
        'fa-video-camera': props.row.nativeData.Type === 'camera',
        'fa-mobile': props.row.nativeData.Type === 'other'
      }"
    />
    <span>{{ macName }}</span>
  </div>
</template>

<script>
/**
 * компонент - ячейка mac.
 * компонент локальный: используется только в StatClientsTable.vue
 * @component
 */

import helpers from '@/helpers';

export default {
  name: 'MacCell',
  components: {},
  props: {
    props: {
      type: Object,
      required: true
    }
  },
  computed: {
    macName() {
      return helpers.getFormatMac(this.props.row.mac);
    }
  },
  methods: {
  }
};
</script>

<style lang="css" scoped>
.mac-cell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.muted {
  color: var(--gray-light);
}
</style>
