<template>
  <div class="row">
    <!--    <div class="col-lg-12 filters-in-statclients">-->
    <!--      <div class="row">-->
    <!--        <div class="col-lg-12 filters">-->
    <!--          <div class="filters__search">-->
    <!--            <div class="input-group input-group-sm mr-1">-->
    <!--              <span class="input-group-addon filters__field-icon">-->
    <!--                <i class="fa fa-search"></i>-->
    <!--              </span>-->
    <!--              <input-->
    <!--                id="search"-->
    <!--                name="search"-->
    <!--                :placeholder="$t('aps.search')"-->
    <!--                class="form-control form-control-sm filters__field"-->
    <!--                v-model="filters.query"-->
    <!--                @input="disableStatMode()"-->
    <!--              />-->
    <!--              <span class="clear-input text-muted" v-if="filters.query" @click="filters.query = ''">-->
    <!--                <i class="fa fa-remove"></i>-->
    <!--              </span>-->
    <!--            </div>-->
    <!--            <div class="d-flex flex-column justify-content-start align-items-start">-->
    <!--&lt;!&ndash;              <div>&ndash;&gt;-->
    <!--&lt;!&ndash;                <date-picker&ndash;&gt;-->
    <!--&lt;!&ndash;                  input-class="mx-input  text-right input-group-sm datepicker-custom-height-for-statclients_27"&ndash;&gt;-->
    <!--&lt;!&ndash;                   :show-icon="false"&ndash;&gt;-->
    <!--&lt;!&ndash;                  v-model="drPickerDateForRating"&ndash;&gt;-->
    <!--&lt;!&ndash;                  @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceededForRating"&ndash;&gt;-->
    <!--&lt;!&ndash;                  :show-error="isShowingDateRangeLimitExceededErrorForRating"&ndash;&gt;-->
    <!--&lt;!&ndash;                  enable-max-date-range-limit&ndash;&gt;-->
    <!--&lt;!&ndash;                />&ndash;&gt;-->
    <!--&lt;!&ndash;              </div>&ndash;&gt;-->
    <!--              <div>-->
    <!--                <div-->
    <!--                  v-show="isShowingDateRangeLimitExceededErrorForRating === true && !statVisible"-->
    <!--                  class="date-range-limit-exceeded-error-for-rating"-->
    <!--                >-->
    <!--                  <span-->
    <!--                    style="font-size: 0.7em;"-->
    <!--                    v-show="isShowingDateRangeLimitExceededErrorForRating === true"-->
    <!--                    class="help is-danger"-->
    <!--                  >-->
    <!--                    {{ $t('general.dateRangeLimitExceededError') }}-->
    <!--                    {{ moment.duration(dateRangeLimitExceededErrorRangeLimitForRating).humanize() }}-->
    <!--                  </span>-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->

    <!--          <form class="mb-1" @submit.prevent="handleFiltersSubmit">-->
    <!--            <div class="filters__multiple" :class="{ 'mt-1': isShowingDateRangeLimitExceededErrorForRating }">-->
    <!--              <div class="input-group input-group-sm filters__sort flex-wrap mr-1">-->
    <!--&lt;!&ndash;                <label for="filter" class="filters__label lables-in-statclients-filters">&ndash;&gt;-->
    <!--&lt;!&ndash;                  <small>{{ $t('general.filtersSort') }}</small>&ndash;&gt;-->
    <!--&lt;!&ndash;                </label>&ndash;&gt;-->
    <!--                <span class="input-group-addon filters__field-icon">-->
    <!--                  <i-->
    <!--                    class="fa text-primary icon-sort"-->
    <!--                    :class="[filters.sort_by.order === 1 ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc']"-->
    <!--                    @click="toggleSortMode()"-->
    <!--                  />-->
    <!--                </span>-->
    <!--                <select @change="handleSelectInFilters"  id="filter" class="form-control form-control-sm filters__field" v-model="filters.sort_by.field">-->
    <!--                  <option value="rx_bytes">{{ $t('statistics.byRxTraffic') }}</option>-->
    <!--                  <option value="tx_bytes">{{ $t('statistics.byTxTraffic') }}</option>-->
    <!--                  <option value="duration">{{ $t('statistics.byDuration') }}</option>-->
    <!--                </select>-->
    <!--              </div>-->

    <!--              <div class="input-group input-group-sm filters__pagination flex-wrap mr-1">-->
    <!--&lt;!&ndash;                <label for="page-limit" class="filters__label lables-in-statclients-filters">&ndash;&gt;-->
    <!--&lt;!&ndash;                  <small>{{ $t('general.filterPageLimitSmall') }}</small>&ndash;&gt;-->
    <!--&lt;!&ndash;                </label>&ndash;&gt;-->
    <!--                <span class="input-group-addon">-->
    <!--                  <i class="fa fa-list"></i>-->
    <!--                </span>-->
    <!--                <input-->
    <!--                  id="page-limit"-->
    <!--                  name="page-limit"-->
    <!--                  type="number"-->
    <!--                  class="form-control filters__input"-->
    <!--                  v-model="filters.page.limit"-->
    <!--                  @input="pageLimitHandler"-->
    <!--                />-->
    <!--              </div>-->

    <!--              <div class="input-group input-group-sm filters__pagination flex-wrap mr-1">-->
    <!--&lt;!&ndash;                <label for="page-offset" class="filters__label lables-in-statclients-filters">&ndash;&gt;-->
    <!--&lt;!&ndash;                  <small>{{ $t('general.filtersPageOffset') }}</small>&ndash;&gt;-->
    <!--&lt;!&ndash;                </label>&ndash;&gt;-->
    <!--                <span class="input-group-addon">-->
    <!--                  <i class="fa fa-list-ol"></i>-->
    <!--                </span>-->
    <!--                <input-->
    <!--                  id="page-offset"-->
    <!--                  name="page-offset"-->
    <!--                  type="number"-->
    <!--                  class="form-control filters__input"-->
    <!--                  v-model="filters.page.offset"-->
    <!--                  @input="pageOffsetHandeler"-->
    <!--                />-->
    <!--              </div>-->

    <!--              <div class="input-group input-group-sm filters__user flex-wrap">-->
    <!--&lt;!&ndash;                <label for="username-filter" class="filters__label lables-in-statclients-filters">&ndash;&gt;-->
    <!--&lt;!&ndash;                  <small>{{ $t('statistics.filtersUsers') }}</small>&ndash;&gt;-->
    <!--&lt;!&ndash;                </label>&ndash;&gt;-->

    <!--                <span class="input-group-addon">-->
    <!--                  <i class="fa fa-user"></i>-->
    <!--                </span>-->
    <!--                <select-->
    <!--                  @change="handleSelectInFilters"-->
    <!--                  id="username-filter"-->
    <!--                  class="form-control form-control-sm filters__field"-->
    <!--                  v-model="filters.q.username"-->
    <!--                >-->
    <!--                  <option value>{{ $t('statistics.allUsers') }}</option>-->
    <!--                  <option :value="{ $gt: '' }">{{ $t('statistics.authorized') }}</option>-->
    <!--                </select>-->
    <!--              </div>-->
    <!--            </div>-->

    <!--            <div class="filters__multiple d-flex flex-wrap">-->
    <!--              <div class="d-flex flex-wrap last-row-filters-block-in-statclients">-->
    <!--                &lt;!&ndash;              <div class="input-group input-group-sm  flex-wrap mr-1 ">&ndash;&gt;-->
    <!--                &lt;!&ndash;                <label for="wlan-filter" class="filters__label lables-in-statclients-filters">&ndash;&gt;-->
    <!--                &lt;!&ndash;                  <small>{{ $t('statistics.filtersWLAN') }}</small>&ndash;&gt;-->
    <!--                &lt;!&ndash;                </label>&ndash;&gt;-->
    <!--                &lt;!&ndash;                <span class="input-group-addon filters__field-icon height-custom-for-filters-in-statclients">&ndash;&gt;-->
    <!--                &lt;!&ndash;                  <i class="fa fa-wifi"></i>&ndash;&gt;-->
    <!--                &lt;!&ndash;                </span>&ndash;&gt;-->
    <!--                &lt;!&ndash;                <select&ndash;&gt;-->
    <!--                &lt;!&ndash;                  class="form-control form-control-sm filters__field filters__field&#45;&#45;wlans selectpicker height-custom-for-filters-in-statclients"&ndash;&gt;-->
    <!--                &lt;!&ndash;                  v-model="filters.q.wlan_id"&ndash;&gt;-->
    <!--                &lt;!&ndash;                  id="wlan-filter"&ndash;&gt;-->
    <!--                &lt;!&ndash;                >&ndash;&gt;-->
    <!--                &lt;!&ndash;                  <option :value="''">{{ $t('aps.allWlans') }}</option>&ndash;&gt;-->
    <!--                &lt;!&ndash;                  <option v-for="wlan in wlansList" v-if="wlansList" :value="wlan.id" :key="wlan.id">&ndash;&gt;-->
    <!--                &lt;!&ndash;                    {{ wlan.ssid }}&ndash;&gt;-->
    <!--                &lt;!&ndash;                  </option>&ndash;&gt;-->
    <!--                &lt;!&ndash;                </select>&ndash;&gt;-->
    <!--                &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--                <div-->
    <!--                  class="input-group input-group-sm flex-wrap mr-1 min-width-for-filters-in-statsclients multiselector-in-statclients"-->
    <!--                >-->
    <!--&lt;!&ndash;                  <label for="wlan-filter" class="filters__label lables-in-statclients-filters">&ndash;&gt;-->
    <!--&lt;!&ndash;                    <small>{{ $t('statistics.filtersWLAN') }}</small>&ndash;&gt;-->
    <!--&lt;!&ndash;                  </label>&ndash;&gt;-->
    <!--                  <div class="d-flex w-100">-->
    <!--                    <div>-->
    <!--                      <span class="input-group-addon filters__field-icon">-->
    <!--                        <i class="fa fa-wifi"></i>-->
    <!--                      </span>-->
    <!--                    </div>-->
    <!--                    <MultiselectWrapperForUseInFilters>-->
    <!--                    <Multiselect-->
    <!--                      id="wlan-filter"-->
    <!--                      class=" multiselector-in-statclients"-->
    <!--                      v-model="selectedWlanInMultiselector"-->
    <!--                      :multiple="true"-->
    <!--                      :max="1"-->
    <!--                      @input="selectWlan"-->
    <!--                      :options="wlansList || []"-->
    <!--                      trackBy="id"-->
    <!--                      label="ssid"-->
    <!--                      :disabled="isDisable"-->
    <!--                      :placeholder="$t('aps.allWlans')"-->
    <!--                      :selectLabel="$t('general.select')"-->
    <!--                      deselectLabel="x"-->
    <!--                      selectedLabel=""-->
    <!--                    >-->
    <!--                      <template v-slot:noOptions>-->
    <!--                        {{ $t('general.notFound') }}-->
    <!--                      </template>-->
    <!--                      <template v-slot:noResult>-->
    <!--                        {{ $t('general.notFound') }}-->
    <!--                      </template>-->
    <!--                    </Multiselect>-->
    <!--                    </MultiselectWrapperForUseInFilters>-->
    <!--                  </div>-->
    <!--                </div>-->

    <!--                &lt;!&ndash;              <div class="input-group input-group-sm flex-wrap mr-1">&ndash;&gt;-->
    <!--                &lt;!&ndash;                <label for="cpe" class="filters__label lables-in-statclients-filters">&ndash;&gt;-->
    <!--                &lt;!&ndash;                  <small>{{ $t('statistics.filtersCPE') }}</small>&ndash;&gt;-->
    <!--                &lt;!&ndash;                </label>&ndash;&gt;-->
    <!--                &lt;!&ndash;                <span class="input-group-addon height-custom-for-filters-in-statclients">&ndash;&gt;-->
    <!--                &lt;!&ndash;                  <img data-v-486104dd src="/static/img/router-icon.svg" alt class="img img-responsive cpe-icon " />&ndash;&gt;-->
    <!--                &lt;!&ndash;                </span>&ndash;&gt;-->
    <!--                &lt;!&ndash;                <select id="cpe" name="cpe" class="form-control filters__field height-custom-for-filters-in-statclients" v-model="filters.q.cpe_id">&ndash;&gt;-->
    <!--                &lt;!&ndash;                  <option :value="''">{{ $t('statistics.allCpes') }}</option>&ndash;&gt;-->
    <!--                &lt;!&ndash;                  <option v-for="cpe in cpesList" v-if="cpesList" :value="cpe.id" :key="cpe.id">{{ cpe.name }}</option>&ndash;&gt;-->
    <!--                &lt;!&ndash;                </select>&ndash;&gt;-->
    <!--                &lt;!&ndash;              </div>&ndash;&gt;-->

    <!--                <div-->
    <!--                  class="input-group input-group-sm  flex-wrap mr-1 min-width-for-filters-in-statsclients multiselector-in-statclients"-->
    <!--                >-->
    <!--&lt;!&ndash;                  <label for="wlan-filter" class="filters__label lables-in-statclients-filters">&ndash;&gt;-->
    <!--&lt;!&ndash;                    <small>{{ $t('statistics.filtersCPE') }}</small>&ndash;&gt;-->
    <!--&lt;!&ndash;                  </label>&ndash;&gt;-->
    <!--                  &lt;!&ndash;                <span class="input-group-addon filters__field-icon height-custom-for-filters-in-statclients">&ndash;&gt;-->
    <!--                  &lt;!&ndash;                  <i class="fa fa-wifi"></i>&ndash;&gt;-->
    <!--                  &lt;!&ndash;                </span>&ndash;&gt;-->
    <!--                  <div class="d-flex w-100">-->
    <!--                    <div>-->
    <!--                      <span class="input-group-addon filters__field-icon ">-->
    <!--&lt;!&ndash;                        <i class="fa fa-wifi"></i>&ndash;&gt;-->
    <!--                                          <img-->
    <!--                    class="img img-responsive cpe-icon"-->
    <!--                    src="/static/img/router-icon.svg"-->
    <!--                    alt-->
    <!--                  />-->
    <!--                      </span>-->
    <!--                    </div>-->
    <!--                    <MultiselectWrapperForUseInFilters>-->
    <!--                    <Multiselect-->
    <!--                      id="cpe"-->
    <!--                      class=" multiselector-in-statclients"-->
    <!--                      v-model="selectedCpeInMultiselector"-->
    <!--                      :multiple="true"-->
    <!--                      :max="1"-->
    <!--                      @input="selectCpe"-->
    <!--                      :options="cpesListWithName || []"-->
    <!--                      trackBy="id"-->
    <!--                      label="name"-->
    <!--                      :disabled="isDisable"-->
    <!--                      :placeholder="$t('statistics.allCpes')"-->
    <!--                      :selectLabel="$t('general.select')"-->
    <!--                      deselectLabel="x"-->
    <!--                      selectedLabel=""-->
    <!--                    >-->
    <!--                      <template v-slot:noOptions>-->
    <!--                        {{ $t('general.notFound') }}-->
    <!--                      </template>-->
    <!--                      <template v-slot:noResult>-->
    <!--                        {{ $t('general.notFound') }}-->
    <!--                      </template>-->
    <!--                    </Multiselect>-->
    <!--                      </MultiselectWrapperForUseInFilters>-->
    <!--                  </div>-->
    <!--                </div>-->

    <!--                <div class="input-group input-group-sm filters__user flex-wrap mr-1 min-width-for-filters-in-statsclients">-->
    <!--&lt;!&ndash;                  <label for="radio" class="filters__label lables-in-statclients-filters">&ndash;&gt;-->
    <!--&lt;!&ndash;                    <small>{{ $t('statistics.filtersRadio') }}</small>&ndash;&gt;-->
    <!--&lt;!&ndash;                  </label>&ndash;&gt;-->
    <!--                  <span class="input-group-addon filters__field-icon ">-->
    <!--                    <i class="fa fa-plug"></i>-->
    <!--                  </span>-->
    <!--                  <input-->
    <!--                    @input="handleSelectInFiltersWithDelay"-->
    <!--                    id="radio"-->
    <!--                    name="radio"-->
    <!--                    class="form-control form-control-sm filters__field "-->
    <!--                    v-model="filters.q.radio_id.$regex"-->
    <!--                    placeholder="radio0, eth0.3508"-->
    <!--                  />-->
    <!--                  <span-->
    <!--                    class="clear-input text-muted"-->
    <!--                    v-if="filters.q.radio_id.$regex"-->
    <!--                    @click="clearRadioIdHandler"-->
    <!--                  >-->
    <!--                    <i class="fa fa-remove"></i>-->
    <!--                  </span>-->
    <!--                </div>-->

    <!--&lt;!&ndash;                <div class="input-group input-group-sm d-flex align-items-end min-width-for-filters-in-statsclients">&ndash;&gt;-->
    <!--&lt;!&ndash;                  <div class="input-group input-group-sm flex-wrap">&ndash;&gt;-->
    <!--&lt;!&ndash;                    <label for="radio" class="filters__label lables-in-statclients-filters">&ndash;&gt;-->
    <!--&lt;!&ndash;                      <small>{{ $t('general.location') }}</small>&ndash;&gt;-->
    <!--&lt;!&ndash;                    </label>&ndash;&gt;-->
    <!--&lt;!&ndash;                    <div class="d-flex w-100">&ndash;&gt;-->
    <!--&lt;!&ndash;                      <div>&ndash;&gt;-->
    <!--&lt;!&ndash;                        <span class="input-group-addon filters__field-icon height-custom-for-filters-in-statclients">&ndash;&gt;-->
    <!--&lt;!&ndash;                          <i class="fa fa-map-marker"></i>&ndash;&gt;-->
    <!--&lt;!&ndash;                        </span>&ndash;&gt;-->
    <!--&lt;!&ndash;                      </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                      <div>&ndash;&gt;-->
    <!--&lt;!&ndash;                        <select&ndash;&gt;-->
    <!--&lt;!&ndash;                          class="form-control form-control-sm filters__field height-custom-for-filters-in-statclients height-custom-for-filters-in-statclients-custom-padding location-filters-in-statsclients"&ndash;&gt;-->
    <!--&lt;!&ndash;                          v-model="filters.location"&ndash;&gt;-->
    <!--&lt;!&ndash;                        >&ndash;&gt;-->
    <!--&lt;!&ndash;                          <option :selected="true" :value="'All locations'">{{ $t('general.allLocations') }}</option>&ndash;&gt;-->
    <!--&lt;!&ndash;                          <option v-for="location in locationsList" :value="location.id" :key="location.id">&ndash;&gt;-->
    <!--&lt;!&ndash;                            {{ location.name }}&ndash;&gt;-->
    <!--&lt;!&ndash;                          </option>&ndash;&gt;-->
    <!--&lt;!&ndash;                        </select>&ndash;&gt;-->
    <!--&lt;!&ndash;                      </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                  </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                <div&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                  v-if="this.filters.location !== 'All locations'"&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                  class="d-flex justify-content-start align-items-center  with-child-wrapper ">&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                  <div>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                    <input&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                      type="checkbox"&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                      v-model="filters.with_childs"&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                    />&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                  </div>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                  <div>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                    <small class="with-child">{{$t('general.withChild')}}</small>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                  </div>&ndash;&gt;&ndash;&gt;-->

    <!--&lt;!&ndash;                  &lt;!&ndash;                </div>&ndash;&gt;&ndash;&gt;-->

    <!--&lt;!&ndash;                  <div&ndash;&gt;-->
    <!--&lt;!&ndash;                    class="input-group input-group-sm d-flex align-items-end ml-0 mr-1 align-self-end height-custom-for-filters-in-statclients"&ndash;&gt;-->
    <!--&lt;!&ndash;                  >&ndash;&gt;-->
    <!--&lt;!&ndash;                    <div class="d-flex switcher-with-childs-in-stat-clients-wrapper align-items-end">&ndash;&gt;-->
    <!--&lt;!&ndash;                      <div class="d-flex align-items-end justify-content-start">&ndash;&gt;-->
    <!--&lt;!&ndash;                        <Switch-component&ndash;&gt;-->
    <!--&lt;!&ndash;                          :disabled="this.filters.location === 'All locations'"&ndash;&gt;-->
    <!--&lt;!&ndash;                          v-model="filters.with_childs"&ndash;&gt;-->
    <!--&lt;!&ndash;                          class="switcher-with-childs-in-stat-clients"&ndash;&gt;-->
    <!--&lt;!&ndash;                        />&ndash;&gt;-->
    <!--&lt;!&ndash;                      </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                      <div&ndash;&gt;-->
    <!--&lt;!&ndash;                        class="input-group input-group-sm filters__location switcher-with-childs-in-stat-clients-caption"&ndash;&gt;-->
    <!--&lt;!&ndash;                      >&ndash;&gt;-->
    <!--&lt;!&ndash;                        <small class="with-child">{{ $t('general.withChild') }}</small>&ndash;&gt;-->
    <!--&lt;!&ndash;                      </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                    </div>&ndash;&gt;-->
    <!--&lt;!&ndash;                  </div>&ndash;&gt;-->






    <!--&lt;!&ndash;                </div>&ndash;&gt;-->

    <!--                      <div class="input-group input-group-sm d-flex  min-width-for-filters-in-statsclients mt-0">-->

    <!--&lt;!&ndash;                                                   <div class="input-group input-group-sm d-flex align-items-end min-width-for-filters-in-statsclients input-group-for-location-select-in-statclients&#45;&#45;filters ">&ndash;&gt;-->
    <!--                                                                       <span class="input-group-addon filters__field-icon">-->
    <!--                  <i class="fa fa-map-marker"-->
    <!--                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>-->
    <!--                </span>-->
    <!--                  <SelectComponentV2-->
    <!--                    v-if="showLocationFilter"-->
    <!--                    no-wrap-selected-option-->
    <!--                    class="select-location-in-statclients&#45;&#45;filters"-->
    <!--                    enable-max-option-width-->
    <!--                    small-size-->
    <!--                    show-delete-button-->
    <!--                    v-model="locationInFiltersData"-->
    <!--                    track-by="name"-->
    <!--                    option-id-name="id"-->
    <!--                    :no-options-found="$t('general.noResultForSearch')"-->
    <!--                    :async-function="requestLocations.bind(this)"-->
    <!--                    :placeholder="$t('portalStats.selectLocation')"-->
    <!--                    @select="selectLocationInFilters"-->
    <!--                  >-->
    <!--                    <template #dropdown-start>-->
    <!--                      <div class="ml-h mt-1">-->
    <!--                        <Switch-component-->
    <!--                          @input="selectWithChildsInFilters"-->
    <!--                          :label="$t('general.withChild')"-->
    <!--                          v-model="filters.with_childs"-->
    <!--                          class=""-->
    <!--                        />-->
    <!--                      </div>-->
    <!--                    </template>-->
    <!--                  </SelectComponentV2>-->
    <!--&lt;!&ndash;                </div>&ndash;&gt;-->
    <!--                      </div>-->

    <!--              </div>-->

    <!--              &lt;!&ndash;              <div class="input-group input-group-sm flex-wrap mr-1  justify-content-end enable-filters-button align-self-end">&ndash;&gt;-->
    <!--              &lt;!&ndash;                <button class="btn btn-primary filters__smaller-button "&ndash;&gt;-->
    <!--              &lt;!&ndash;                        type="submit">&ndash;&gt;-->
    <!--              &lt;!&ndash;                  {{ $t('general.applyFilters') }}&ndash;&gt;-->
    <!--              &lt;!&ndash;                </button>&ndash;&gt;-->
    <!--              &lt;!&ndash;              </div>&ndash;&gt;-->
    <!--              &lt;!&ndash;              <div class="input-group input-group-sm flex-wrap mr-1  justify-content-end enable-filters-button align-self-end">&ndash;&gt;-->
    <!--              &lt;!&ndash;                <button class=" btn-outline-secondary btn btn-sm mt-1 " @click="resetAllFilters">&ndash;&gt;-->
    <!--              &lt;!&ndash;                  <i class="fa fa-close"></i>&ndash;&gt;-->
    <!--              &lt;!&ndash;                  {{ $t('general.resetAllFilters') }}&ndash;&gt;-->
    <!--              &lt;!&ndash;                </button>&ndash;&gt;-->

    <!--              &lt;!&ndash;            </div>&ndash;&gt;-->
    <!--&lt;!&ndash;              <div&ndash;&gt;-->
    <!--&lt;!&ndash;                class="d-flex flex-wrap h-100 justify-content-end align-items-end align-self-end button-apply-last-row-filters-block-in-statclients"&ndash;&gt;-->
    <!--&lt;!&ndash;              >&ndash;&gt;-->
    <!--&lt;!&ndash;                <div&ndash;&gt;-->
    <!--&lt;!&ndash;                  class="h-100 input-group input-group-sm flex-wrap justify-content-end enable-filters-button align-self-end filter-buttons-in-statclients flex-gap-1"&ndash;&gt;-->
    <!--&lt;!&ndash;                >&ndash;&gt;-->
    <!--&lt;!&ndash;                  <button&ndash;&gt;-->
    <!--&lt;!&ndash;                    class="btn btn-primary filters__smaller-button"&ndash;&gt;-->
    <!--&lt;!&ndash;                    type="submit"&ndash;&gt;-->
    <!--&lt;!&ndash;                    :disabled="isShowingDateRangeLimitExceededErrorForRating"&ndash;&gt;-->
    <!--&lt;!&ndash;                  >&ndash;&gt;-->
    <!--&lt;!&ndash;                    {{ $t('general.applyFilters') }}&ndash;&gt;-->
    <!--&lt;!&ndash;                  </button>&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                  <button class=" btn-outline-secondary btn btn-sm  " @click="resetAllFilters">&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                    <i class="fa fa-close"></i>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                    {{ $t('general.resetAllFilters') }}&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                  &lt;!&ndash;                  </button>&ndash;&gt;&ndash;&gt;-->
    <!--&lt;!&ndash;                </div>&ndash;&gt;-->
    <!--&lt;!&ndash;              </div>&ndash;&gt;-->
    <!--              &lt;!&ndash;              <div class="input-group input-group-sm flex-wrap mr-1  justify-content-end enable-filters-button align-self-end">&ndash;&gt;-->
    <!--              &lt;!&ndash;                <button class=" btn-outline-secondary btn btn-sm mt-1 " @click="resetAllFilters">&ndash;&gt;-->
    <!--              &lt;!&ndash;                  <i class="fa fa-close"></i>&ndash;&gt;-->
    <!--              &lt;!&ndash;                  {{ $t('general.resetAllFilters') }}&ndash;&gt;-->
    <!--              &lt;!&ndash;                </button>&ndash;&gt;-->

    <!--              &lt;!&ndash;            </div>&ndash;&gt;-->

    <!--              &lt;!&ndash;              <button class="btn btn-primary filters__smaller-button align-self-end" type="submit">&ndash;&gt;-->
    <!--              &lt;!&ndash;                {{ $t('general.applyFilters') }}&ndash;&gt;-->
    <!--              &lt;!&ndash;              </button>&ndash;&gt;-->
    <!--            </div>-->
    <!--            <div class="filters__multiple">-->
    <!--              <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">-->
    <!--                <button-->
    <!--                  class="btn-outline-secondary btn btn-sm mt-1 new-modern-style-btn-in-filters"-->
    <!--                  @click.stop.prevent="resetAllFilters"-->
    <!--                >-->
    <!--                  <i class="fa fa-close"></i>-->
    <!--                  {{ $t('general.resetAllFilters') }}-->
    <!--                </button>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </form>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->


    <div class="col-lg-12 filters-in-statclients">
      <div class="row">
        <div class="col-lg-12 filters">
          <div class="">
            <div class="row  mb-h">
              <div class="col-lg-3 mb-h">
                <div class=" w-100 input-group input-group-sm mr-1">
                  <span class="input-group-addon filters__field-icon">
                    <i class="fa fa-search"></i>
                  </span>
                  <input
                    id="search"
                    name="search"
                    :placeholder="$t('aps.search')"
                    class="form-control form-control-sm filters__field"
                    v-model="tableSearchValue"
                    @input="disableStatMode()"
                  />
                  <span v-show="tableSearchValue"
                    class="clear-input text-muted"
                    @click="tableSearchValue = ''"
                  >
                    <i class="fa fa-remove"></i>
                  </span>
                </div>
              </div>

              <div class="col-lg-3 mb-h" style="z-index: 999998;" v-if="areLocationsVisible">
                <div
                  class="input-group input-group-sm d-flex   mt-0 w-100">

                  <!--                                                   <div class="input-group input-group-sm d-flex align-items-end min-width-for-filters-in-statsclients input-group-for-location-select-in-statclients&#45;&#45;filters ">-->
                  <span class="input-group-addon filters__field-icon">
                    <i class="fa fa-map-marker"
                      :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                  </span>
                  <SelectComponentV3ForUseInLocationSelectors
                    v-if="showLocationFilter"
                    no-wrap-selected-option
                    class="select-location-in-statclients--filters"
                    enable-max-option-width
                    small-size
                    :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                    :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="selectWithChildsInFilters"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>
                  <!--                </div>-->
                </div>
              </div>

              <div class="col-lg-3 mb-h">


                <div class="input-group input-group-sm w-100  flex-wrap">
                  <span class="input-group-addon">
                    <i class="fa fa-user"></i>
                  </span>
                  <select
                    @change="handleSelectInFilters"
                    id="username-filter"
                    class="form-control form-control-sm filters__field"
                    v-model="filters.q.username"
                  >
                    <option value>{{ $t('statistics.allUsers') }}</option>
                    <option :value="{ $gt: '' }">{{ $t('statistics.authorized') }}</option>
                  </select>
                </div>

              </div>


              <div class="col-lg-3 mb-h" style="max-height: 27px !important;">
                <div
                  class="input-group input-group-sm flex-wrap mr-1  multiselector-in-statclients w-100"
                >
                  <div class="d-flex w-100">
                    <div>
                      <span class="input-group-addon filters__field-icon">
                        <i class="fa fa-wifi"></i>
                      </span>
                    </div>
                    <MultiselectWrapperForUseInFilters>
                      <Multiselect
                        id="wlan-filter"
                        class=" multiselector-in-statclients"
                        v-model="selectedWlanInMultiselector"
                        :multiple="true"
                        :max="1"
                        @input="selectWlan"
                        :options="wlansList || []"
                        trackBy="id"
                        label="ssid"
                        :disabled="isDisable"
                        :placeholder="$t('aps.allWlans')"
                        :selectLabel="$t('general.select')"
                        deselectLabel="x"
                        selectedLabel=""
                      >
                        <template v-slot:noOptions>
                          {{ $t('general.notFound') }}
                        </template>
                        <template v-slot:noResult>
                          {{ $t('general.notFound') }}
                        </template>
                      </Multiselect>
                    </MultiselectWrapperForUseInFilters>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 mb-h" style="max-height: 27px !important;">
                <div
                  class="input-group input-group-sm  flex-wrap mr-1  multiselector-in-statclients"
                >
                  <div class="d-flex w-100">
                    <div>
                      <span class="input-group-addon filters__field-icon ">
                        <img
                                            class="img img-responsive cpe-icon"
                                            src="/static/img/router-icon.svg"
                                            alt
                                          />
                      </span>
                    </div>
                    <MultiselectWrapperForUseInFilters>
                      <Multiselect
                        id="cpe"
                        class=" multiselector-in-statclients"
                        v-model="selectedCpeInMultiselector"
                        :multiple="true"
                        :max="1"
                        @input="selectCpe"
                        :options="cpesListWithName || []"
                        trackBy="id"
                        label="name"
                        :disabled="isDisable"
                        :placeholder="$t('statistics.allCpes')"
                        :selectLabel="$t('general.select')"
                        deselectLabel="x"
                        selectedLabel=""
                      >
                        <template v-slot:noOptions>
                          {{ $t('general.notFound') }}
                        </template>
                        <template v-slot:noResult>
                          {{ $t('general.notFound') }}
                        </template>
                      </Multiselect>
                    </MultiselectWrapperForUseInFilters>
                  </div>
                </div>

              </div>


              <div class="col-lg-3 mb-h" v-if="!isWLCProduct">
                <div
                  class="input-group input-group-sm  flex-wrap mr-1 ">
                  <span class="input-group-addon filters__field-icon ">
                    <i class="fa fa-plug"></i>
                  </span>
                  <input
                    @input="handleSelectInFiltersWithDelay"
                    id="radio"
                    name="radio"
                    class="form-control form-control-sm filters__field "
                    v-model="filters.q.radio_id.$regex"
                    placeholder="radio0, eth0.3508"
                  />
                  <span
                    class="clear-input text-muted"
                    v-if="filters.q.radio_id.$regex"
                    @click="clearRadioIdHandler"
                  >
                    <i class="fa fa-remove"></i>
                  </span>
                </div>
              </div>
              <!--              <div class="col-lg-2 mb-h">-->
              <!--                <div class="input-group input-group-sm filters__pagination flex-wrap mr-1">-->
              <!--                <span class="input-group-addon">-->
              <!--                  <i class="fa fa-list"></i>-->
              <!--                </span>-->
              <!--                  <input-->
              <!--                    id="page-limit"-->
              <!--                    name="page-limit"-->
              <!--                    type="number"-->
              <!--                    class="form-control filters__input"-->
              <!--                    v-model="filters.page.limit"-->
              <!--                    @input="pageLimitHandler"-->
              <!--                  />-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="col-lg-2 mb-h">-->
              <!--                <div class="input-group input-group-sm filters__pagination flex-wrap mr-1">-->
              <!--                <span class="input-group-addon">-->
              <!--                  <i class="fa fa-list-ol"></i>-->
              <!--                </span>-->
              <!--                  <input-->
              <!--                    id="page-offset"-->
              <!--                    name="page-offset"-->
              <!--                    type="number"-->
              <!--                    class="form-control filters__input"-->
              <!--                    v-model="filters.page.offset"-->
              <!--                    @input="pageOffsetHandeler"-->
              <!--                  />-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
            <div class="row mt-0">
              <div class="col-lg-4">
                <div class="filters__multiple">
                  <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
                    <WButton secondary outline sm
                      v-if="isFiltersActive"
                      @click.stop.prevent="resetAllFilters"
                    >
                      <i class="fa fa-close"></i>
                      {{ $t('general.resetAllFilters') }}
                    </WButton>
                  </div>
                </div>
              </div>
            </div>


          </div>


        </div>
      </div>
    </div>


    <div class="col-lg-12">
      <div class="clients" :class="{ 'stat-visible': statVisible }">
        <div class="clients-rating-list animated fadeIn">
          <div class="animated fadeIn sticky-top">
            <div class="row">
              <div class="col-lg-12">
                <div class="card">
                  <!--                  <div class="loader loader-backdrop loader-backdrop-statclients" v-if="isClientsRatingLoading"></div>-->
                  <div v-if="isClientsRatingLoading"><Loader-spinner></Loader-spinner></div>
                  <div class="card-block">
                    <StatClientsTable
                      :items="clientsRatingListFull || []"
                      :searchQuery="filters.query"
                      :searchFn="tableSearchFn"
                      :selectedRowClientsIds="[]"
                      :activeRowClientId="statClientId"
                      :timeSlice="{
                        startDate: filters.selectedStartDateForRating,
                        endDate: filters.selectedEndDateForRating
                      }"
                      :isDataLoading="false"
                      :isDisabled="false"
                      :isOneColMode="Boolean(statVisible)"
                      :isSelectionRowsModeEnabled="false"
                      @on-cell-click="toggleEdit"
                      @on-sort-change="handleSortChange"
                      @on-client-info-click="goToClients"
                    >
                      <template v-slot:table-menu>
                        <div class="control-panel">
                          <div class="control-panel__container left">
                          </div>
                          <div v-show="!statVisible"
                            class="control-panel__container right"
                          >
                            <div class="d-flex flex-column justify-content-start align-items-start" style="position: relative;">
                              <date-picker
                                :onChange="drPickerDateForRatingHandler.bind(this)"
                                v-model="drPickerDateForRating"
                                @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceededForRating"
                                :show-error="isShowingDateRangeLimitExceededErrorForRating"
                                enable-max-date-range-limit
                              />
                              <div v-show="isShowingDateRangeLimitExceededErrorForRating === true && !statVisible"
                                class="date-range-limit-exceeded-error-for-rating"
                              >
                                <span v-show="isShowingDateRangeLimitExceededErrorForRating === true"
                                  style="font-size: 0.7em; margin: 0;"
                                  class="help is-danger"
                                >
                                  {{ $t('general.dateRangeLimitExceededError') }}
                                  {{ moment.duration(dateRangeLimitExceededErrorRangeLimitForRating).humanize() }}
                                </span>
                              </div>
                            </div>
                            <TableDataDownloader v-if="clientsRatingListFull"
                              :data="formatToCsv(filteredAndSortedStatclientsList)"
                              :data-for-excel="formatAllStatsToExcel()"
                              :filename="getFileNameForDownloadFiles($t('downloadFilesNames.statClients'))"
                              :availableReports="{
                                csv: true,
                                xlsx: {
                                  enabled: true,
                                  autoColumnsWidth: true
                                },
                                pdf: true
                              }"
                            />
                            <WButton warning
                              @click="showRawModal = true"
                              :disabled="isShowingDateRangeLimitExceededErrorForRating"
                            >
                              <i class="fa fa-hourglass-half mr-q" />
                              {{ $t('statistics.rawButton') }}
                            </WButton>
                          </div>
                        </div>
                      </template>
                      <!--
                        todo переписать пагинацию на нативную с рефакторингом запросов.
                        Пока что нативная из StatClientsTable несовместима с логикой запросов
                      -->
                      <template v-slot:table-actions-bottom>
                        <div v-show="
                              canLoadMore && clientsRatingListFull &&
                              clientsRatingListFull.length &&
                              totalResult - clientsRatingListFull.length && !Boolean(statVisible)
                            "
                            class="card-block text-center p-0"
                          >
                          <WButton link
                            @click="loadMore"
                          >
                            {{ $t('portal.paginationLoad') }}
                            <span v-if="filters.page.limit < totalResult - clientsRatingListFull.length">
                              {{ filters.page.limit }}
                            </span>
                            <span v-else>
                              {{ totalResult - clientsRatingListFull.length }}
                            </span>
                            {{ $t('statistics.paginationCPERaitngsOutOf') }}
                            {{ totalResult - clientsRatingListFull.length }}
                          </WButton>
                        </div>
                      </template>
                    </StatClientsTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="client-stat" v-if="statVisible">
          <div class="row">
            <div class="col-lg-12">
              <div class="card sticky-top">
                <!--                <div class="loader loader-backdrop loader-backdrop-statclients" v-if="isStatsLoading"></div>-->
                <div v-if="isStatsLoading"><Loader-spinner></Loader-spinner></div>
                <div class="card-header d-flex align-items-center">
                  <i class="fa fa-user"></i>
                  {{
                  clientSelecteId
                  .replace(/(.{2})/g, '$1:')
                  .slice(0, -1)
                  .toUpperCase()
                  }}
                  <small>
                    (
                    <i class="fa fa-wifi"></i>
                    {{ filters.selectedWLAN !== '' ? wlanObj(this.filters.selectedWLAN) : $t('aps.allWlans') }}
                    )
                  </small>
                  <WButton secondary outline sm
                    customClass="ml-auto"
                    @click="disableStatMode"
                  >
                    <i class="fa fa-close"></i>
                  </WButton>
                </div>
                <div class="card-block">
                  <div class="row">
                    <div class="col-lg-12 d-flex align-items-start justify-content-between flex-wrap">
                      <WButton secondary
                        v-if="showGoToClietnsButton"
                        @click="goToClients(clientSelectedMacAddr)"
                      >
                        {{ $t('statistics.goToClientsSettings') }}
                      </WButton>
                      <div class="float-right d-flex flex-column justify-content-start align-items-start">
                        <div>
                          <date-picker
                            v-model="drPickerDateForStat"
                            :onChange="handleDateChangeForStat.bind(this)"
                            @isDateRangeLimitEnabledAndExceeded="dateRangeLimitEnabledAndExceededForStat"
                            :show-error="isShowingDateRangeLimitExceededErrorForStat"
                            enable-max-date-range-limit
                          />
                        </div>
                        <div>
                          <div v-show="isShowingDateRangeLimitExceededErrorForStat === true" class="mb-h mb-1">
                            <span
                              style="font-size: 0.8em;"
                              v-show="isShowingDateRangeLimitExceededErrorForStat === true"
                              class="help is-danger"
                            >
                              {{ $t('general.dateRangeLimitExceededError') }}
                              {{ moment.duration(dateRangeLimitExceededErrorRangeLimitForStat).humanize() }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row mt-1">
                      <div class="col-lg-12 d-flex align-items-center justify-content-end">
                        <TableDataDownloader
                          :data-for-excel="convertToNewExcelExportModuleFormat(txRxToCsv(txRxChart), $t('statistics.txRxChartTitle'))"
                          v-if="txRxChart"
                          class="mr-h"
                          :data="txRxToCsv(txRxChart)"
                          :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statClients')}-${clientSelecteId}-${$t('statistics.txRxChartTitle')}`)"
                          :availableReports="{
                            csv: true,
                            xlsx: {
                              enabled: true,
                              autoColumnsWidth: true
                            },
                            pdf: true
                          }"
                        />
                        <SavePngButton class="color-brand mr-1" id="txRxChart"  :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statClients')}-${clientSelecteId}_${$t('statistics.txRxChartTitle')}`)"></SavePngButton>
                      </div>
                      <div class="col-lg-12">
                        <highcharts :options="txRxChart" id="txRxChart" ref="highcharts"></highcharts>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12 d-flex align-items-center justify-content-end">
                        <TableDataDownloader
                          :data-for-excel="convertToNewExcelExportModuleFormat(txRxToCsv(txRxRateChart), $t('statistics.txRxRateChartTitle'))"
                          v-if="txRxRateChart"
                          class="mr-h"
                          :data="txRxToCsv(txRxRateChart)"
                          :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statClients')}-${clientSelecteId}-${$t('statistics.txRxRateChartTitle')}`)"
                          :availableReports="{
                            csv: true,
                            xlsx: {
                              enabled: true,
                              autoColumnsWidth: true
                            },
                            pdf: true
                          }"
                        />
                        <SavePngButton class="color-brand mr-1" id="txRxRateChart" :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statClients')}-${clientSelecteId}_${$t('statistics.txRxRateChartTitle')}`)"></SavePngButton>
                      </div>
                      <div class="col-lg-12">
                        <highcharts :options="txRxRateChart" id="txRxRateChart" ref="highcharts"></highcharts>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-lg-12 d-flex align-items-center justify-content-end">
                        <TableDataDownloader
                          :data-for-excel="convertToNewExcelExportModuleFormat(sessionToCsv(sessionChart.series[0].data), $t('statistics.sessionClientsChartTitle'))"
                          v-if="sessionChart"
                          class="mr-h"
                          :data="sessionToCsv(sessionChart.series[0].data)"
                          :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statClients')}-${clientSelecteId}-${$t('statistics.sessionClientsChartTitle')}`)"
                          :availableReports="{
                            csv: true,
                            xlsx: {
                              enabled: true,
                              autoColumnsWidth: true
                            },
                            pdf: true
                          }"
                        />
                        <SavePngButton
                          class="color-brand mr-1"
                          id="sessionChart"
                          :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.statClients')}-${clientSelecteId}_${$t('statistics.sessionClientsChartTitle')}`)"
                        ></SavePngButton>
                      </div>
                      <div class="col-lg-12">
                        <highcharts :options="sessionChart" id="sessionChart" ref="highcharts"></highcharts>
                      </div>
                    </div>
                    <div class="actions-buttons">
                      <div>
                        <WButton secondary outline
                          @click="disableStatMode"
                        >
                          {{ $t('general.close') }}
                        </WButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      title="Raw Data Modal"
      v-model="showRawModal"
      id="rawDataModal"
      class="modal-primary"
      effect="fade/zoom"
      closeBtn
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('statistics.rawModal') }}</h4>

      <div>
        <p>{{ $t('statistics.rawConfirmation') }}</p>

        <table>
          <tbody>
            <tr>
              <td colspan="2" class="font-weight-bold">{{ $t('statistics.period') }}</td>
            </tr>
            <tr>
              <td class="pr-1">{{ $t('statistics.from') }}</td>
              <td>{{ (drPickerDateForRating.startDate / 1000) | moment }}</td>
            </tr>
            <tr>
              <td class="pr-1">{{ $t('statistics.to') }}</td>
              <td>{{ (drPickerDateForRating.endDate / 1000) | moment }}</td>
            </tr>

            <tr>
              <td colspan="2" class="font-weight-bold pt-1">{{ $t('general.filters') }}</td>
            </tr>
            <tr>
              <td class="pr-1">{{ $t('statistics.wlanForReportModal') }}</td>
              <td>{{ filters.q.wlan_id.trim() !== '' ? wlanNamebyId(filters.q.wlan_id) : $t('general.all') }}</td>
            </tr>
            <tr>
              <td class="pr-1">{{ $t('general.cpe') }}</td>
              <td>{{ filters.q.cpe_id.trim() !== '' ? cpeNamebyId(filters.q.cpe_id) : $t('general.all') }}</td>
            </tr>
            <tr>
              <td class="pr-1">{{ $t('wlans.interface') }}</td>
              <td>{{ filters.q.radio_id.$regex.trim() !== '' ? filters.q.radio_id.$regex : $t('general.all') }}</td>
            </tr>
            <tr>
              <td class="pr-1">{{ $t('statistics.filtersUsers') }}</td>
              <td>{{ filters.q.username === '' ? $t('general.all') : $t('statistics.authorized') }}</td>
            </tr>

            <tr>
              <td colspan="2" class="font-weight-bold pt-1">{{ $t('general.filtersSort') }}</td>
            </tr>
            <tr>
              <td class="pr-1">{{ $t('statistics.field') }}</td>
              <td>{{ getSortFieldName(filters.sort_by.field) }}</td>
            </tr>
            <tr>
              <td class="pr-1">{{ $t('statistics.order') }}</td>
              <td>{{ filters.sort_by.order === 1 ? $t('general.orderAsc') : $t('general.orderDesc') }}</td>
            </tr>

            <tr>
              <td colspan="2" class="font-weight-bold pt-1">{{ $t('statistics.pagination') }}</td>
            </tr>
            <tr>
              <td class="pr-1">{{ $t('statistics.limit') }}</td>
              <td>{{ filters.page.limit }}</td>
            </tr>
            <tr>
              <td class="pr-1">{{ $t('statistics.offset') }}</td>
              <td>{{ filters.page.offset }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div slot="modal-footer" class="modal-footer">
        <WButton
          customClass="mr-auto"
          v-if="!clientsRawList"
          @click="loadClientsRaw"
          :disabled="isClientsRawloading"
        >
        <i class="fa fa-refresh mr-q" :class="{ 'fa-spin': isClientsRawloading }"/>
          {{ $t('statistics.rawRequest') }}
        </WButton>

        <TableDataDownloader
          v-if="clientsRawList"
          class="mr-auto"
          :data="formatRawClients(clientsRawList)"
          :data-for-excel="formatRawClientsToExcel(clientsRawList, $t('statistics.rawRequest'))"
          :filename="getFileNameForDownloadFiles($t('downloadFilesNames.statClientsSessions'))"
          :availableReports="{
            csv: true,
            xlsx: {
              enabled: true,
              autoColumnsWidth: true
            },
            pdf: true
          }"
        />
        <WButton secondary
          customClass="ml-1"
          @click="closeRawModal"
        >
          {{ $t('general.close') }}
        </WButton>
      </div>
    </modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { progressbar } from 'vue-strap';
import Multiselect from 'vue-multiselect';
import Modal from '@/components/Modal.vue';

import statClientService from '@/services/statClientService';
import commonService from '@/services/commonService';
import locationService from '@/services/locationService';

import Filters from '@/filters';
import helpers from '@/helpers';
import DatePicker from '@/components/date-picker.vue';
import SavePngButton from '@/components/save-png-button.vue';
import EmptyCellValue from '@/components/empty-cell-value.vue';
import SwitchComponent from '@/components/Universal/Switch-component.vue';
import SelectComponentV2 from '@/components/Universal/select/select-componentV2.vue';
import MultiselectWrapperForUseInFilters from '@/components/general/filters/MultiselectWrapperForUseInFilters.vue';
import SelectComponentV3ForUseInLocationSelectors from '@/components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

import {
  StatClientsTable
} from './components';

import {
  intervalUpdateModeMixin,
  debounceMixin
} from '@/mixins';

const { mapGetters: localeMapGetters } = createNamespacedHelpers('locale');
const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');

export default {
  name: 'Statclients',
  components: {
    Modal,
    progressbar,
    SavePngButton,
    EmptyCellValue,
    DatePicker,
    SwitchComponent,
    Multiselect,
    SelectComponentV2,
    MultiselectWrapperForUseInFilters,
    SelectComponentV3ForUseInLocationSelectors,
    StatClientsTable
  },
  mixins: [
    intervalUpdateModeMixin,
    debounceMixin
  ],
  data() {
    return {
      delayTimer: null,
      showLocationFilter: true,
      locationInFiltersData: [],
      offset: 0,
      totalResult: false,
      canLoadMore: false,
      isShowingDateRangeLimitExceededErrorForRating: false,
      dateRangeLimitExceededErrorRangeLimitForRating: 0,
      isShowingDateRangeLimitExceededErrorForStat: false,
      dateRangeLimitExceededErrorRangeLimitForStat: 0,
      selectedCpeInMultiselector: false,
      selectedWlanInMultiselector: false,
      showRawModal: false,
      clientsObject: {},
      searchQuery: '',
      enableStats: '',
      selectedClients: [],
      statClientId: '',
      currentClientStats: [],
      clientSelectedMacAddr: '',
      drPickerDateForRating: {
        startDate: moment().startOf('day').valueOf(),
        endDate: moment().endOf('day').valueOf()
      },
      drPickerDateForStat: {
        startDate: '',
        endDate: ''
      },
      tableSearchValue: '',
      filters: {
        location: 'All locations',
        with_childs: false,
        query: '',
        interfacesFilter: '',
        connectedFilter: '',
        selectedWLAN: '',
        selectedSortField: 'rx_bytes',
        selectedStartDateForRating: moment().startOf('day').valueOf(),
        selectedEndDateForRating: moment().endOf('day').valueOf(),
        page: {
          limit: 5000,
          offset: 0
        },
        q: {
          radio_id: {
            $regex: ''
          },
          cpe_id: '',
          wlan_id: '',
          username: ''
        },
        sort_by: {
          field: 'rx_bytes',
          order: -1 /* 1 for ascending, -1 for descending */
        }
      },

      txRxChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
          text: this.$t('statistics.pan')
        },
        title: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
          text: this.$t('statistics.txRxChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime',
          min: '',
          max: ''
        },
        yAxis: {
          title: {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
            text: this.$t('statistics.txRxChartMbytes')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#4dbd74'
            }
          ]
        },
        tooltip: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
          pointFormat: `{series.name}: <b>{point.y} ${this.$t('statistics.txRxChartMbytes')}</b>`
        },

        series: [
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
            name: this.$t('statistics.txRxChartTX'),
            data: [],
            color: '#0277BD'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
            name: this.$t('statistics.txRxChartRX'),
            data: [],
            color: '#81C784'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOntxRxChart
            name: this.$t('statistics.txRxChartTXandRX'),
            data: [],
            color: '#E57373'
          }
        ]
      },

      sessionChart: {
        chart: {
          type: 'area',
          height: '250',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnSessionChart
          text: this.$t('statistics.pan')
        },
        title: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnSessionChart
          text: this.$t('statistics.sessionClientsChartTitle'),
          x: -20 // center
        },
        plotOptions: {
          area: {
            lineWidth: 3,
            states: {
              hover: {
                lineWidth: 6
              }
            }
          }
        },
        tooltip: {
          // эти подписи меняется при смене языка через функцию changeCaptionsWithI18nOnSessionChart
          contextF: (oldThis) => {
            let label = '';
            if (oldThis.series.name === this.$t('statistics.sessionClientsChartSeriesConnected')) {
              label = `${moment(oldThis.x).utc().format('dddd, D MMM YYYY, H:mm')}<br><b>${
                oldThis.series.name
              } </b><br><br> <b>${this.$t('statistics.sessionClientsChartTooltipCPE')}:</b> ${this.cpeObj(
                oldThis.point.cpe
              )}<br> <b>${this.$t('statistics.sessionClientsChartTooltipFrequency')}:</b> ${
                oldThis.point.freq
              } GHz<br><b>${this.$t('statistics.sessionClientsChartTooltipMode')}:</b> ${
                oldThis.point.mode
              } <br> <b>${this.$t('statistics.sessionClientsChartTooltipWLAN')}:</b> ${this.wlanObj(
                oldThis.point.wlan
              )}`;
            } else {
              label = `${moment(oldThis.x).utc().format('dddd, D MMM YYYY, H:mm')}<br>`;
            }
            return label;
          },
          formatter(instance) {
            return instance.options.contextF(this);
          }
        },
        xAxis: {
          type: 'datetime',
          min: '',
          max: ''
        },
        yAxis: {
          title: {
            text: ''
          },
          minTickInterval: 1,
          gridLineWidth: 0,
          // эти подписи меняется при смене языка через функцию changeCaptionsWithI18nOnSessionChart
          categories: [
            this.$t('statistics.sessionClientsChartAxisOffline'),
            this.$t('statistics.sessionClientsChartAxisOnline')
          ],
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#cccccc'
            }
          ],
          min: 0,
          max: 1
        },
        series: [
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnSessionChart
            name: this.$t('statistics.sessionClientsChartSeriesConnected'),
            data: [],
            color: '#81C784'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnSessionChart
            name: this.$t('statistics.sessionClientsChartSeriesDisconnected'),
            data: [],
            color: '#BCC5D0'
          }
        ],

        step: 'left'
      },

      txRxRateChart: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnTxRxRateChart
          text: this.$t('statistics.pan')
        },
        title: {
          // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnTxRxRateChart
          text: this.$t('statistics.txRxRateChartTitle'),
          x: -20 // center
        },
        xAxis: {
          type: 'datetime',
          min: '',
          max: ''
        },
        yAxis: {
          title: {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnTxRxRateChart
            text: this.$t('statistics.txRxRateChartMbytess')
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#000000'
            }
          ]
        },
        series: [
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnTxRxRateChart
            name: this.$t('statistics.txRxRateChartTXRate'),
            data: [],
            color: '#0277BD'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnTxRxRateChart
            name: this.$t('statistics.txRxRateChartRXRate'),
            data: [],
            color: '#81C784'
          },
          {
            // это подпись меняется при смене языка через функцию changeCaptionsWithI18nOnTxRxRateChart
            name: this.$t('statistics.txRxRateChartTXandRXRate'),
            data: [],
            color: '#E57373'
          }
        ]
      },
      txRxRateChartPerUser: {
        chart: {
          type: 'spline',
          height: '400',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        title: {
          text: 'TX/RX Rate Per User',
          x: -20 // center
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: 'MByte/s'
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: '#000000'
            }
          ]
        },
        series: [
          {
            name: 'TX Rate',
            data: [],
            color: '#0277BD'
          },
          {
            name: 'RX Rate',
            data: [],
            color: '#81C784'
          },
          {
            name: 'TX+RX Rate',
            data: [],
            color: '#E57373'
          }
        ]
      },
      sortedClientsRatingListFull: []
    };
  },
  computed: {
    ...WLCProductMapGetters(['isWLCProduct']),
    ...localeMapGetters(['isRuLocale', 'locale']),
    // список статистики из таблицы со всеми примененными фильтрами и сортировками
    // нужен, чтобы скачать отчет из таблицы с актуальными фильтрами и сортировками
    filteredAndSortedStatclientsList() {
      // todo refactor
      let filteredAndSortedClients = this.sortedClientsRatingListFull.length
        ? this.sortedClientsRatingListFull
        : this.clientsRatingListFull;

      // фильтрация
      filteredAndSortedClients = Filters.clientStatSearchQuery(filteredAndSortedClients, this.filters.query);

      return filteredAndSortedClients;
    },
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    showGoToClietnsButton() {
      return this.$store.state.userData.role === 'operator' ||
        this.$store.state.userData.role === 'admin';
    },
    clientsRatingListLength() {
      return this.$store.state.clientsRatingList.length;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    isStatsLoading() {
      return this.$store.state.loadingStats;
    },
    isClientsRawloading() {
      return this.$store.state.loadingClientsRaw;
    },
    // isFiltersActive() {
    //   return (
    //     this.filters.query !== '' ||
    //       this.filters.sort_by.field !== 'rx_bytes' || this.filters.sort_by.order !== -1 ||
    //       this.filters.location !== 'All locations' || this.filters.q.cpe_id !== '' ||
    //       this.filters.q.wlan_id !== '' || this.filters.q.username !== '' ||
    //       this.filters.q.radio_id.$regex !== ''
    //
    //   );
    // },

    isFiltersActive() {
      return (
        this.filters.query !== '' ||
          this.filters.sort_by.field !== 'rx_bytes' || this.filters.sort_by.order !== -1 ||
          this.filters.q.cpe_id !== '' ||
          this.filters.q.wlan_id !== '' || this.filters.q.username !== '' ||
          this.filters.q.radio_id.$regex !== '' ||
          (this.areLocationsVisible ? this.filters.location !== 'All locations' : false)

      );
    },

    statVisible() {
      return this.$store.state.statClientId;
    },
    clientSelecteId() {
      return this.$store.state.statClientId;
    },
    fullPeriod() {
      const periodStart = parseInt(this.filters.selectedStartDateForRating) / 1000;
      let periodStop = parseInt(this.filters.selectedEndDateForRating) / 1000;
      if (periodStop * 1000 > Date.now()) {
        periodStop = Date.now() / 1000;
      }
      return periodStop - periodStart;
    },
    clientsRatingListFull() {
      if (this.$store.state.clientsRatingList) {
        const clientsRatingListFull = JSON.parse(JSON.stringify(this.$store.state.clientsRatingList));

        clientsRatingListFull.forEach((client) => {
          const findClient = commonService.clientObjbyId(client.id);
          client.offPeriod = this.totalOff(client.sessions);
          if (findClient) {
            client.Type = findClient.Type;
            client.data = findClient.data;
          } else {
            client.Type = 'other';
            client.data = { description: '', rtsp_stream: '' };
          }
        });
        return clientsRatingListFull;
      }
      return [];
    },
    clientsRawList() {
      return this.$store.state.clientsRawList;
    },
    isClientsRatingLoading() {
      return this.$store.state.loadingClientsRating;
    },
    cpesList: {
      get() {
        return this.$store.state.cpesList;
      },
      set() {}
    },
    cpesListWithName: {
      get() {
        const cpeList = JSON.parse(JSON.stringify(this.cpesList));
        // console.log(cpeList);
        if (cpeList === false) {
          return [];
        }
        const cpesListWithName = cpeList.filter((cpe) => {
          if (cpe.name !== '') {
            return true;
          }
          return false;
        });
        return cpesListWithName;
      },
      set() {}
    },
    wlansList: {
      get() {
        return this.$store.state.wlansList;
      },
      set() {}
    },
    clientSelectedId() {
      this.statClientId = this.$store.state.statClientId;
      return this.$store.state.statClientId;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    /**
     * Returns filters.q without empty fields
     * @return {object}
     */
    formattedQ() {
      const copy = { ...this.filters.q };
      if (!copy.wlan_id) {
        delete copy.wlan_id;
      }
      if (!copy.cpe_id) {
        delete copy.cpe_id;
      }
      if (!copy.radio_id.$regex) {
        delete copy.radio_id;
      }
      if (!copy.username) {
        delete copy.username;
      }
      return copy;
    }
  },
  methods: {
    handleSortChange(data) {
      const { sortedTableRows } = data;

      const sortedStatclientsList = sortedTableRows
        .map(tableRow => tableRow.nativeData);

      this.sortedClientsRatingListFull = sortedStatclientsList;
    },
    /**
     * Меняет разнообразные подписи и имена на графике sessionChart, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOnSessionChart() {
      this.sessionChart.subtitle.text = this.$t('statistics.pan');
      this.sessionChart.title.text = this.$t('statistics.sessionClientsChartTitle');
      this.sessionChart.yAxis.categories = [
        this.$t('statistics.sessionClientsChartAxisOffline'),
        this.$t('statistics.sessionClientsChartAxisOnline')
      ];
      this.sessionChart.series[0].name = this.$t('statistics.sessionClientsChartSeriesConnected');
      this.sessionChart.series[1].name = this.$t('statistics.sessionClientsChartSeriesDisconnected');
      this.sessionChart.tooltip = {
        contextF: (oldThis) => {
          let label = '';
          if (oldThis.series.name === this.$t('statistics.sessionClientsChartSeriesConnected')) {
            label = `${moment(oldThis.x).utc().format('dddd, D MMM YYYY, H:mm')}<br><b>${
              oldThis.series.name
            } </b><br><br> <b>${this.$t('statistics.sessionClientsChartTooltipCPE')}:</b> ${this.cpeObj(
              oldThis.point.cpe
            )}<br> <b>${this.$t('statistics.sessionClientsChartTooltipFrequency')}:</b> ${
              oldThis.point.freq
            } GHz<br><b>${this.$t('statistics.sessionClientsChartTooltipMode')}:</b> ${
              oldThis.point.mode
            } <br> <b>${this.$t('statistics.sessionClientsChartTooltipWLAN')}:</b> ${this.wlanObj(
              oldThis.point.wlan
            )}`;
          } else {
            label = `${moment(oldThis.x).utc().format('dddd, D MMM YYYY, H:mm')}<br>`;
          }
          return label;
        },
        formatter(instance) {
          return instance.options.contextF(this);
        }
      }
    },
    /**
     * Меняет разнообразные подписи и имена на графике txRxRateChart, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOnTxRxRateChart() {
      this.txRxRateChart.subtitle.text = this.$t('statistics.pan');
      this.txRxRateChart.title.text = this.$t('statistics.txRxRateChartTitle');
      this.txRxRateChart.yAxis.title.text = this.$t('statistics.txRxRateChartMbytess');
      this.txRxRateChart.series[0].name = this.$t('statistics.txRxRateChartTXRate');
      this.txRxRateChart.series[1].name = this.$t('statistics.txRxRateChartRXRate');
      this.txRxRateChart.series[2].name = this.$t('statistics.txRxRateChartTXandRXRate');
    },
    /**
     * Меняет разнообразные подписи и имена на графике txRxChart, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOntxRxChart() {
      this.txRxChart.subtitle.text = this.$t('statistics.pan');
      this.txRxChart.title.text = this.$t('statistics.txRxChartTitle');
      this.txRxChart.yAxis.title.text = this.$t('statistics.txRxChartMbytes');
      this.txRxChart.tooltip.pointFormat = `{series.name}: <b>{point.y} ${this.$t('statistics.txRxChartMbytes')}</b>`
      this.txRxChart.series[0].name = this.$t('statistics.txRxChartTX');
      this.txRxChart.series[1].name = this.$t('statistics.txRxChartRX');
      this.txRxChart.series[2].name = this.$t('statistics.txRxChartTXandRX');
    },
    /**
     * Меняет разнообразные подписи и имена на всех отображаемых  графиках, заполняя значениями в текущей выбраной локали
     */
    changeCaptionsWithI18nOnCharts() {
      this.changeCaptionsWithI18nOntxRxChart();
      this.changeCaptionsWithI18nOnTxRxRateChart();
      this.changeCaptionsWithI18nOnSessionChart();

    },
    goToClients(clientMacAddr) {
      // console.log('go to clients');
      // this.clientSelectedMacAddr
      this.saveComponentStateDataForRestore();
      this.$router.push({ name: 'Clients', params: { clientIdFormClientsStatisic: clientMacAddr } });
    },

        selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      // this.handleFiltersSubmit()
          this.handleSelectInFilters()

    },
    clearRadioIdHandler() {
      this.filters.q.radio_id.$regex = '';
      this.handleSelectInFilters();
    },
    pageLimitHandler() {
      this.disableStatMode();
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }
      this.delayTimer = setTimeout(() => {
        this.filters.page.limit = this.validatePagination(this.filters.page.limit, 1)
        this.handleFiltersSubmit();
        this.delayTimer = null;
      }, 1000);
    },
    pageOffsetHandeler() {
      this.disableStatMode();
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }
      this.delayTimer = setTimeout(() => {
        this.filters.page.offset = this.validatePagination(this.filters.page.offset);
        this.handleFiltersSubmitDontClearOffset();
        this.delayTimer = null;
      }, 1000);
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    drPickerDateForRatingHandler() {
      this.disableStatMode();
      if (this.isShowingDateRangeLimitExceededErrorForRating) {
        return;
      }
      this.handleFiltersSubmit();
    },
    handleSelectInFiltersWithDelay() {
      this.disableStatMode();
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }
      this.delayTimer = setTimeout(() => {
        this.handleFiltersSubmit();
        this.delayTimer = null;
      }, 1000);

    },
    handleSelectInFilters() {
      this.disableStatMode();
      this.handleFiltersSubmit();
    },
    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    selectWithChildsInFilters() {
     // console.log('chenge with childs')
      this.handleSelectInFilters();
    },

    saveComponentStateDataForRestore(){
      // из стора
      const cpesList = JSON.parse(JSON.stringify(this.$store.state.cpesList));
      const statClientId = this.$store.state.statClientId;
      const clientsSessionsList = JSON.parse(JSON.stringify(this.$store.state.clientsSessionsList));
      const clientsRatingList = JSON.parse(JSON.stringify(this.$store.state.clientsRatingList));
      const wlansList =  JSON.parse(JSON.stringify(this.$store.state.wlansList))
      const wlansItems = JSON.parse(JSON.stringify(this.$store.state.wlansItems))
      const selectedCpeInMultiselector = JSON.parse(JSON.stringify(this.selectedCpeInMultiselector))
      const selectedWlanInMultiselector = JSON.parse(JSON.stringify(this.selectedWlanInMultiselector))



      // из стейта компонента
      const filters = JSON.parse(JSON.stringify(this.filters));
      const drPickerDateForRating = JSON.parse(JSON.stringify(this.drPickerDateForRating));
      const drPickerDateForStat = JSON.parse(JSON.stringify(this.drPickerDateForStat));
      const locationInFiltersData = JSON.parse(JSON.stringify(this.locationInFiltersData));

      // const txRxChart = JSON.parse(JSON.stringify(this.txRxChart));
      // const txRxRateChart = JSON.parse(JSON.stringify(this.txRxRateChart));
      // const sessionChart = JSON.parse(JSON.stringify(this.sessionChart));
      const offset = this.offset;
      const totalResult = this.totalResult;
      const canLoadMore = this.canLoadMore;
      const clientSelectedMacAddr = this.clientSelectedMacAddr;
      const statClientIdInComponent = this.statClientId;


      const clientsStatisticComponentStateDataForRestoreInClientsStatisticPage = {
        filters,
        drPickerDateForRating,
        drPickerDateForStat,
        offset,
        totalResult,
        canLoadMore,
        statClientId,
        cpesList,
        // txRxChart,
        // txRxRateChart,
        // sessionChart,
        clientsSessionsList,
        wlansList,
        wlansItems,
        selectedCpeInMultiselector,
        selectedWlanInMultiselector,
        clientSelectedMacAddr,
        statClientIdInComponent,
        clientsRatingList,
        locationInFiltersData

      };
      // console.log(clientsStatisticComponentStateDataForRestoreInClientsStatisticPage)
      localStorage.setItem('clientsStatisticComponentStateDataForRestoreInClientsStatisticPage',
        JSON.stringify(clientsStatisticComponentStateDataForRestoreInClientsStatisticPage));
    },
    loadComponentStateDataForRestoreAndRestore() {
      const clientsStatisticComponentStateDataForRestoreInClientsStatisticPage = JSON.parse(localStorage.getItem('clientsStatisticComponentStateDataForRestoreInClientsStatisticPage'));
      if (clientsStatisticComponentStateDataForRestoreInClientsStatisticPage) {
        // восстанавлиеаем в store
        this.$store.commit('setCpesList', clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.cpesList ?? false)
        this.$store.commit('setClientsSessionsList', clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.clientsSessionsList ?? false)
        this.$store.commit('setClientsRatingList', clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.clientsRatingList ?? false)
        this.$store.commit('setWlansList', clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.wlansList ?? false)
        this.$store.commit('setWlansItems', clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.wlansItems ?? false)


        // восстанавливаем в стейт компонента
        if (!!clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.selectedCpeInMultiselector) {
          this.$set(this, 'selectedCpeInMultiselector',
            JSON.parse(JSON.stringify(clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.selectedCpeInMultiselector)))
        }


        if (!!clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.selectedWlanInMultiselector) {
          this.$set(this, 'selectedWlanInMultiselector',
            JSON.parse(JSON.stringify(clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.selectedWlanInMultiselector)))
        }


        if (!!clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.filters) {
          this.$set(this, 'filters',
            JSON.parse(JSON.stringify(clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.filters)))
        }

        if (!!clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.drPickerDateForRating) {
          this.$set(this, 'drPickerDateForRating',
            JSON.parse(JSON.stringify(clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.drPickerDateForRating)))
        }

        if (!!clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.drPickerDateForStat) {
          this.$set(this, 'drPickerDateForStat',
            JSON.parse(JSON.stringify(clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.drPickerDateForStat)))
        }

        if (!!clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.locationInFiltersData) {
          this.$set(this, 'locationInFiltersData',
            JSON.parse(JSON.stringify(clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.locationInFiltersData)))
        }

        // if (!!clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.txRxChart) {
        //   this.$set(this, 'txRxChart',
        //     JSON.parse(JSON.stringify(clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.txRxChart)))
        // }
        // if (!!clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.txRxRateChart) {
        //   this.$set(this, 'txRxRateChart',
        //     JSON.parse(JSON.stringify(clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.txRxRateChart)))
        // }
        // if (!!clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.sessionChart) {
        //   this.$set(this, 'sessionChart',
        //     JSON.parse(JSON.stringify(clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.sessionChart)))
        // }
        this.offset = clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.offset;
        this.totalResult = clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.totalResult;
        this.canLoadMore = clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.canLoadMore;

        this.$nextTick(() => {
          this.$store.commit('setClientStatId', clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.statClientId ?? '')
          this.clientSelectedMacAddr = clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.clientSelectedMacAddr ?? ''
          this.statClientId = clientsStatisticComponentStateDataForRestoreInClientsStatisticPage.statClientIdInComponent ?? ''
          this.$nextTick(() => {
            this.responseStats();
          })
        });
      }
    },
    removeComponentStateDataForRestore(){
      localStorage.removeItem('clientsStatisticComponentStateDataForRestoreInClientsStatisticPage');
    },
    checkComponentStateDataForRestore() {
      return !!localStorage.getItem('clientsStatisticComponentStateDataForRestoreInClientsStatisticPage');
    },
    loadMore() {
      this.offset++;
      this.filters.page.offset = this.offset * this.filters.page.limit;
      // console.log(this.filters.offset);
      statClientService.getClientsSessionsWithLocationFilterWithPagination(this);
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    convertToNewExcelExportModuleFormat(inputDataForOldExcelModule, nameForExcelList){
      return helpers.convertFromOldExcelModuleFormatToNewExcelModuleFormat(inputDataForOldExcelModule, nameForExcelList)
    },
    dateRangeLimitEnabledAndExceededForRating(payload) {
      if (payload.dateRangeLimitEnabledAndExceeded === true) {
        this.isShowingDateRangeLimitExceededErrorForRating = true;
        this.dateRangeLimitExceededErrorRangeLimitForRating = payload.maxDateRangeLimit;
      } else {
        this.isShowingDateRangeLimitExceededErrorForRating = false;
        this.dateRangeLimitExceededErrorRangeLimitForRating = payload.maxDateRangeLimit;
      }
    },
    dateRangeLimitEnabledAndExceededForStat(payload) {
      // console.log(payload)
      if (payload.dateRangeLimitEnabledAndExceeded === true) {
        this.isShowingDateRangeLimitExceededErrorForStat = true;
        this.dateRangeLimitExceededErrorRangeLimitForStat = payload.maxDateRangeLimit;
        this.refreshCharts();
      } else {
        this.isShowingDateRangeLimitExceededErrorForStat = false;
        this.dateRangeLimitExceededErrorRangeLimitForStat = payload.maxDateRangeLimit;
      }
    },

    locationPartyGetField(id, field) {
      try {
        const filed = field;
        const showLocationPartyFromId = commonService.showLocationPartyFromLocationId(id);
        if (showLocationPartyFromId !== undefined && showLocationPartyFromId.hasOwnProperty(`${filed}`)) {
          return showLocationPartyFromId[field];
        }
        return '';
      } catch (e) {
        console.log(e);
        return '';
      }
    },
    getCpeById(id) {
      const cpeObject = commonService.cpeObjbyId(id);
      return cpeObject;
    },
    getLocationById(id) {
      const locationObject = commonService.locationObjbyId(id);
      // console.log(locationObject)
      return locationObject;
    },
    // selectCpe(value) {
    //   // console.log(value)
    //   // console.log(value, id);
    //   if (value === null) {
    //     this.filters.q.cpe_id = '';
    //   } else {
    //     this.filters.q.cpe_id = value.id;
    //   }
    // },
    selectCpe(value) {
      // console.log(value)
      // console.log(value, id);
      if (value.length === 0 || value === null) {
        this.filters.q.cpe_id = '';
      } else {
        this.filters.q.cpe_id = value[0].id;
      }
      this.handleSelectInFilters();
    },
    // selectWlan(value) {
    //   // console.log(value)
    //   // console.log(value, id);
    //   if (value === null) {
    //     this.filters.q.wlan_id = '';
    //   } else {
    //     this.filters.q.wlan_id = value.id;
    //   }
    // },
    selectWlan(value) {
      // console.log(value)
      // console.log(value, id);
      if (value.length === 0 ||value === null) {
        this.filters.q.wlan_id = '';
      } else {
        this.filters.q.wlan_id = value[0].id;
      }
      this.handleSelectInFilters()
    },
    // resetAllFilters() {
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.offset = 0;
    //   this.filters.page.offset = 0;
    //   this.selectedWlanInMultiselector = false;
    //   this.selectedCpeInMultiselector = false;
    //   this.filters = {
    //     // location: this.userBaseLocation,
    //     location: 'All locations',
    //     with_childs: false,
    //     query: '',
    //     interfacesFilter: '',
    //     connectedFilter: '',
    //     selectedWLAN: '',
    //     selectedSortField: 'rx_bytes',
    //     selectedStartDateForRating: moment().startOf('day').valueOf(),
    //     selectedEndDateForRating: moment().endOf('day').valueOf(),
    //     page: {
    //       limit: 100,
    //       offset: 0
    //     },
    //     q: {
    //       radio_id: {
    //         $regex: ''
    //       },
    //       cpe_id: '',
    //       wlan_id: '',
    //       username: ''
    //     },
    //     sort_by: {
    //       field: 'rx_bytes',
    //       order: -1 /* 1 for ascending, -1 for descending */
    //     }
    //   };
    //   this.handleFiltersSubmit();
    // },
    resetAllFilters() {
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.offset = 0;
      this.filters.page.offset = 0;
      this.selectedWlanInMultiselector = false;
      this.selectedCpeInMultiselector = false;
      this.filters = {
        // location: this.userBaseLocation,
       //  location: 'All locations',
        // with_childs: false,
        query: '',
        interfacesFilter: '',
        connectedFilter: '',
        selectedWLAN: '',
        selectedSortField: 'rx_bytes',
        selectedStartDateForRating: moment().startOf('day').valueOf(),
        selectedEndDateForRating: moment().endOf('day').valueOf(),
        page: {
          limit: 100,
          offset: 0
        },
        q: {
          radio_id: {
            $regex: ''
          },
          cpe_id: '',
          wlan_id: '',
          username: ''
        },
        sort_by: {
          field: 'rx_bytes',
          order: -1 /* 1 for ascending, -1 for descending */
        }
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();

      this.handleFiltersSubmit();
    },
    toggleEdit(selectedTableRowData) {
      const { nativeData: { id, mac_addr } } = selectedTableRowData;

      if (this.isShowingDateRangeLimitExceededErrorForStat && this.statVisible) {
        return;
      }

      if (this.isShowingDateRangeLimitExceededErrorForRating && !this.statVisible) {
        return;
      }

      this.$store.commit('setClientStatId', id);
      this.clientSelectedMacAddr = mac_addr;
      this.statClientId = id;
      this.responseStats();
    },
    clientSessionsbyId(id) {
      const clientsSessions = this.$store.state.clientsSessionsList || [];
      let clientObj;
      clientsSessions.forEach((client) => {
        if (client.id === id) {
          clientObj = client;
        }
      });
      return clientObj;
    },
    percentRxTx(rx, tx) {
      const rxPercent = (rx * 100) / (rx + tx);
      if (rxPercent > 1) {
        return Math.round(rxPercent);
      }
      return Math.round(rxPercent * 10) / 10;
    },
    totalOff(sessions) {
      const clientSessions = sessions;
      const periodStart = this.drPickerDateForRating.startDate / 1000;
      let periodStop = this.drPickerDateForRating.endDate / 1000;
      if (periodStop * 1000 > Date.now()) {
        periodStop = Date.now() / 1000;
      }
      const period = periodStop - periodStart;
      if (!sessions) {
        return period;
      }
      let totalOff = period;
      clientSessions.forEach((session) => {
        if (session.start < periodStart && (session.stop > periodStop || session.stop === 0)) {
          totalOff = 0;
        } else if (session.start > periodStart && (session.stop > periodStop || session.stop === 0)) {
          const onPeriod = periodStop - session.start;
          totalOff -= onPeriod;
        } else if (session.start < periodStart && session.stop < periodStop) {
          const onPeriod = session.stop - periodStart;
          totalOff -= onPeriod;
        } else if (session.start > periodStart && session.stop < periodStop) {
          const onPeriod = session.stop - session.start;
          totalOff -= onPeriod;
        }
      });
      return totalOff;
    },
    percentData(total, off) {
      const percentData = (100 * off) / total;
      if (percentData < 0.1 && percentData !== 0) {
        return 0.1;
      }
      if (percentData < 0) {
        return 0;
      }
      if (percentData > 100) {
        return 100;
      }
      return percentData.toFixed(1);
    },
    getFormatMac(mac) {
      return helpers.getFormatMac(mac);
    },
    // функция для фильтрации таблицы по инпуту
    // https://xaksis.github.io/vue-good-table/guide/configuration/search-options.html#searchfn
    tableSearchFn(row, col, cellValue, searchTerm) {
      const { nativeData } = row;

      const { description } = nativeData.data;
      const formattedMac = helpers.getFormatMac(nativeData.id);

      const isDescriptionSearched = description.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
      const isMacSearched = formattedMac.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;

      const result = isDescriptionSearched || isMacSearched;

      return result;
    },
    memoryLoad(total, free) {
      return ((total - free) * 100) / total;
    },
    setFilter(filterName, value) {
      this.selectedClients = [];
      if (this.filters[filterName] !== value) {
        this.filters[filterName] = value;
        return;
      }
      this.filters[filterName] = '';
    },
    // toggleSortMode() {
    //   if (this.filters.sort_by.order === 1) {
    //     this.filters.sort_by.order = -1;
    //   } else {
    //     this.filters.sort_by.order = 1;
    //   }
    // },
    toggleSortMode() {
      if (this.filters.sort_by.order === 1) {
        this.filters.sort_by.order = -1;
      } else {
        this.filters.sort_by.order = 1;
      }
      this.handleSelectInFilters();
    },
    /* Seems deprecated */
    totalTraffic(item) {
      const { clientsRatingList } = this.$store.state;
      let totalRX = 0;
      let totalTX = 0;
      if (item === 'rx') {
        clientsRatingList.forEach((client) => {
          totalRX += client.rx_bytes;
        });
        return totalRX;
      }
      if (item === 'tx') {
        clientsRatingList.forEach((client) => {
          totalTX += client.tx_bytes;
        });
        return totalTX;
      }
    },
    /* Seems deprecated */
    totalClients(item) {
      const { clientsRatingList } = this.$store.state;
      let clients = 0;
      let LBSClients = 0;
      if (item === 'clients') {
        clientsRatingList.forEach((client) => {
          clients += client.connected_clients;
        });
        return clients;
      }
      if (item === 'lbs') {
        clientsRatingList.forEach((client) => {
          LBSClients += client.lbs_clients;
        });
        return LBSClients;
      }
    },
    disableStatMode() {
      this.drPickerDateForStat = {
        startDate: '',
        endDate: ''
      };
      this.isShowingDateRangeLimitExceededErrorForStat = false;
      this.dateRangeLimitExceededErrorRangeLimitForStat = 0;

      this.statClientId = '';
      this.$store.commit('setClientStatId', null);
    },
    cutName(name) {
      return commonService.cutName(name);
    },
    clientObj(id) {
      return commonService.clientObjbyId(id);
    },
    // handleDateChangeForStat() {
    //   statClientService.getClientStat(this);
    //   statClientService.getClientSession(this);
    // },

    handleDateChangeForStat() {
      if (!this.isShowingDateRangeLimitExceededErrorForStat) {
        statClientService.getClientStat(this);
        statClientService.getClientSession(this);
      }
    },
    refreshCharts() {
      this.txRxChart.series[0].data = [];
      this.txRxChart.series[1].data = [];
      this.txRxChart.series[2].data = [];
      this.txRxRateChart.series[0].data = [];
      this.txRxRateChart.series[1].data = [];
      this.txRxRateChart.series[2].data = [];
      this.sessionChart.series[0].data = [];
      this.sessionChart.series[1].data = [];
    },
    cpeObj(id) {
      return commonService.cpeNameForStat(id);
    },
    wlanObj(id) {
      return commonService.wlanNameForStat(id);
    },
    handleFiltersSubmit() {
      // this.filters.page.limit = 100;
      this.filters.page.offset = 0;

      this.filters.selectedWLAN = this.filters.q.wlan_id;
      this.filters.selectedSortField = this.filters.sort_by.field;
      this.filters.selectedStartDateForRating = this.drPickerDateForRating.startDate;
      this.filters.selectedEndDateForRating = this.drPickerDateForRating.endDate;
      this.getClientsSessions();
    },
    handleFiltersSubmitDontClearOffset() {
      this.filters.selectedWLAN = this.filters.q.wlan_id;
      this.filters.selectedSortField = this.filters.sort_by.field;
      this.filters.selectedStartDateForRating = this.drPickerDateForRating.startDate;
      this.filters.selectedEndDateForRating = this.drPickerDateForRating.endDate;
      this.getClientsSessions();
    },
    getClientsSessions() {
      // statClientService.getClientsSessions(this);
      // statClientService.getClientsSessionsWithLocationFilter(this);
      this.$store.commit('clearClientsRatingList');
      this.$store.commit('clearClientsSessionsList');

      statClientService.getClientsSessionsWithLocationFilterWithPagination(this);
    },
    responseStats() {
      this.refreshCharts();
      statClientService.getClientStat(this);
      if (this.filters.selectedWLAN === '') {
        statClientService.getClientSession(this);
      }
    },
    formatBytes(bytes, decimals) {
      return commonService.formatBytes(bytes, decimals);
    },
    formatDateForCSV(value) {
      return moment.unix(value).format('D MMM YYYY, H:mm:ss');
    },
    // formatToCsv(clients) {
    //   if (!clients) {
    //     return [];
    //   }
    //
    //   const csvClients = JSON.parse(JSON.stringify(clients));
    //
    //   return csvClients.map((client) => ({
    //     MAC: client.id ? this.getFormatMac(client.id) : '',
    //     'Authorization data': this.getUsernames(client).length > 0 ? this.getUsernames(client).join(', ') : '',
    //     'Traffic Downloaded': this.formatBytes(client.rx_bytes),
    //     'Traffic Uploaded': this.formatBytes(client.tx_bytes),
    //     'Total Duration (sec.)': this.fullPeriod ? Math.floor(this.fullPeriod) : 0,
    //     'Session Duration (sec.)': client.duration ? client.duration : 0
    //   }));
    // },
    formatToCsv(clients) {
      if (!clients) {
        return [];
      }

      const csvClients = JSON.parse(JSON.stringify(clients));

      const result = csvClients.map((client) => {
        const mapResult = {};
        mapResult[this.$t('statistics.macForDownload')] = client.id ? this.getFormatMac(client.id) : '';
        mapResult[this.$t('statistics.authorizationDataForDownload')] =
          this.getUsernames(client).length > 0 ? this.getUsernames(client).join(', ') : '';
        mapResult[this.$t('statistics.trafficDownloadedForDownload')] = this.formatBytes(client.rx_bytes);
        mapResult[this.$t('statistics.trafficUploadedForDownload')] = this.formatBytes(client.tx_bytes);
        mapResult[this.$t('statistics.totalDurationForDownload')] = this.fullPeriod ? Math.floor(this.fullPeriod) : 0;
        mapResult[this.$t('statistics.sessionDurationForDownload')] = client.duration ? client.duration : 0;
        return mapResult;
        // MAC: client.id ? this.getFormatMac(client.id) : '',
        // 'Authorization data': this.getUsernames(client).length > 0 ? this.getUsernames(client).join(', ') : '',
        // 'Traffic Downloaded': this.formatBytes(client.rx_bytes),
        // 'Traffic Uploaded': this.formatBytes(client.tx_bytes),
        // 'Total Duration (sec.)': this.fullPeriod ? Math.floor(this.fullPeriod) : 0,
        // 'Session Duration (sec.)': client.duration ? client.duration : 0
      });
      return result;
    },
    formatToExcel(data, graphName = 'data') {
      if (data.length === 0) {
        return { [graphName]: [] };
      }
      const result = [];
      data.forEach((row) => {
        // console.log(row)
        if (result.length === 0) {
          result.push([
            // 'MAC',
            // 'Authorization data',
            // 'Traffic Downloaded',
            // 'Traffic Uploaded',
            // 'Total Duration (sec',
            // 'Session Duration (sec'
            this.$t('statistics.macForDownload'),
            this.$t('statistics.authorizationDataForDownload'),
            this.$t('statistics.trafficDownloadedForDownload'),
            this.$t('statistics.trafficUploadedForDownload'),
            this.$t('statistics.totalDurationForDownload'),
            this.$t('statistics.sessionDurationForDownload')
          ]);
        }
        result.push([
          row.id ? this.getFormatMac(row.id) : '',
          this.getUsernames(row).length > 0 ? this.getUsernames(row).join(', ') : '',
          this.formatBytes(row.rx_bytes),
          this.formatBytes(row.tx_bytes),
          this.fullPeriod ? Math.floor(this.fullPeriod) : 0,
          row.duration ? row.duration : 0
        ]);
      });
      return { [graphName]: result };
    },
    formatAllStatsToExcel() {
      return {
        ...this.formatToExcel(this.filteredAndSortedStatclientsList, this.$t('clients.clients'))
        // ...this.formatRawClientsToExcel(this.clientsRawList, this.$t('statistics.rawRequest'))
      };
    },
    // formatRawClients(clients) {
    //   if (!clients) {
    //     return [];
    //   }
    //
    //   const copy = JSON.parse(JSON.stringify(clients));
    //
    //   for (const client of copy) {
    //     let locationName = '';
    //     let companyNameForDownload = '';
    //
    //     const rowCpe = this.getCpeById(client.cpe_id);
    //     if (rowCpe !== undefined && rowCpe.hasOwnProperty('base_location')) {
    //       const baseLocation = rowCpe.base_location;
    //       // console.log(baseLocation)
    //       const location = this.getLocationById(baseLocation);
    //       locationName = location.name;
    //       // console.log(location);
    //       const companyName = this.locationPartyGetField(baseLocation, 'name');
    //       companyNameForDownload = companyName;
    //     }
    //     client.locationName = locationName;
    //     client.companyName = companyNameForDownload;
    //
    //   }
    //
    //   return copy.map((client) => ({
    //     MAC: client.mac ? this.getFormatMac(client.mac) : '',
    //     'Authorization data': client.username || '',
    //     CPE: client.cpe_id ? commonService.cpeNamebyId(client.cpe_id) : '',
    //     WLAN: client.wlan_id || '',
    //     SSID: client.ssid || '',
    //     VLAN: client.radio_id || '',
    //     Start: client.start ? this.formatDateForCSV(client.start) : '',
    //     Stop: client.stop ? this.formatDateForCSV(client.stop) : '',
    //     'Traffic Downloaded': this.formatBytes(client.rx_bytes),
    //     'Traffic Uploaded': this.formatBytes(client.tx_bytes),
    //     IPs:
    //       client.dpi && client.dpi.dest_ips && Array.isArray(client.dpi.dest_ips) ? client.dpi.dest_ips.join(', ') : '',
    //     Location: client.locationName ? client.locationName : '',
    //     Company: client.companyName ? client.companyName : ''
    //   }));
    // },
    // formatRawClients(clients) {
    //   if (!clients) {
    //     return [];
    //   }
    //
    //   const copy = JSON.parse(JSON.stringify(clients));
    //
    //   for (const client of copy) {
    //     let locationName = '';
    //     let companyNameForDownload = '';
    //
    //     const rowCpe = this.getCpeById(client.cpe_id);
    //     if (rowCpe !== undefined && rowCpe.hasOwnProperty('base_location')) {
    //       const baseLocation = rowCpe.base_location;
    //       // console.log(baseLocation)
    //       const location = this.getLocationById(baseLocation);
    //       locationName = location.name;
    //       // console.log(location);
    //       const companyName = this.locationPartyGetField(baseLocation, 'name');
    //       companyNameForDownload = companyName;
    //     }
    //     client.locationName = locationName;
    //     client.companyName = companyNameForDownload;
    //   }
    //
    //   const result = copy.map((client) => {
    //     const mapResult = {};
    //     mapResult[this.$t('statistics.macForDownload')] = client.mac ? this.getFormatMac(client.mac) : '';
    //     mapResult[this.$t('statistics.authorizationDataForDownload')] = client.username || '';
    //     mapResult[this.$t('statistics.cpeForDownload')] = client.cpe_id ? commonService.cpeNamebyId(client.cpe_id) : '';
    //     mapResult[this.$t('statistics.wlanForDownload')] = client.wlan_id || '';
    //     mapResult[this.$t('statistics.ssidForDownload')] = client.ssid || '';
    //     mapResult[this.$t('statistics.vlanForDownload')] = client.radio_id || '';
    //     mapResult[this.$t('statistics.startForDownload')] = client.start ? this.formatDateForCSV(client.start) : '';
    //     mapResult[this.$t('statistics.stopForDownload')] = client.stop ? this.formatDateForCSV(client.stop) : '';
    //     mapResult[this.$t('statistics.trafficDownloadedForDownload')] = this.formatBytes(client.rx_bytes);
    //     mapResult[this.$t('statistics.trafficUploadedForDownload')] = this.formatBytes(client.tx_bytes);
    //     mapResult[this.$t('statistics.ipsForDownload')] =
    //       client.dpi && client.dpi.dest_ips && Array.isArray(client.dpi.dest_ips) ? client.dpi.dest_ips.join(', ') : '';
    //     mapResult[this.$t('statistics.locationForDownload')] = client.locationName ? client.locationName : '';
    //     mapResult[this.$t('statistics.companyForDownload')] = client.companyName ? client.companyName : this.$t('general.no').toLowerCase();
    //
    //     return mapResult;
    //     // MAC: client.mac ? this.getFormatMac(client.mac) : '',
    //     // 'Authorization data': client.username || '',
    //     // CPE: client.cpe_id ? commonService.cpeNamebyId(client.cpe_id) : '',
    //     // WLAN: client.wlan_id || '',
    //     // SSID: client.ssid || '',
    //     // VLAN: client.radio_id || '',
    //     // Start: client.start ? this.formatDateForCSV(client.start) : '',
    //     // Stop: client.stop ? this.formatDateForCSV(client.stop) : '',
    //     // 'Traffic Downloaded': this.formatBytes(client.rx_bytes),
    //     // 'Traffic Uploaded': this.formatBytes(client.tx_bytes),
    //     // IPs:
    //     //   client.dpi && client.dpi.dest_ips && Array.isArray(client.dpi.dest_ips) ? client.dpi.dest_ips.join(', ') : '',
    //     // Location: client.locationName ? client.locationName : '',
    //     // Company: client.companyName ? client.companyName : ''
    //   });
    //   return result;
    // },

    formatRawClients(clients) {
      if (!clients) {
        return [];
      }

      const copy = JSON.parse(JSON.stringify(clients));

      for (const client of copy) {
        let locationName = '';
        let companyNameForDownload = '';

        const rowCpe = this.getCpeById(client.cpe_id);
        if (rowCpe !== undefined && rowCpe.hasOwnProperty('base_location')) {
          const baseLocation = rowCpe.base_location;
          // console.log(baseLocation)
          const location = this.getLocationById(baseLocation);
          locationName = location.name;
          // console.log(location);
          const companyName = this.locationPartyGetField(baseLocation, 'name');
          companyNameForDownload = companyName;
        }
        client.locationName = locationName;
        client.companyName = companyNameForDownload;
      }

      let result;

      if (this.areLocationsVisible) {
        result = copy.map((client) => {
          const mapResult = {};
          mapResult[this.$t('statistics.macForDownload')] = client.mac ? this.getFormatMac(client.mac) : '';
          mapResult[this.$t('statistics.authorizationDataForDownload')] = client.username || '';
          mapResult[this.$t('statistics.cpeForDownload')] = client.cpe_id ? commonService.cpeNamebyId(client.cpe_id) : '';
          mapResult[this.$t('statistics.wlanForDownload')] = client.wlan_id || '';
          mapResult[this.$t('statistics.ssidForDownload')] = client.ssid || '';
          mapResult[this.$t('statistics.vlanForDownload')] = client.radio_id || '';
          mapResult[this.$t('statistics.startForDownload')] = client.start ? this.formatDateForCSV(client.start) : '';
          mapResult[this.$t('statistics.stopForDownload')] = client.stop ? this.formatDateForCSV(client.stop) : '';
          mapResult[this.$t('statistics.trafficDownloadedForDownload')] = this.formatBytes(client.rx_bytes);
          mapResult[this.$t('statistics.trafficUploadedForDownload')] = this.formatBytes(client.tx_bytes);
          mapResult[this.$t('statistics.ipsForDownload')] =
            client.dpi && client.dpi.dest_ips && Array.isArray(client.dpi.dest_ips) ? client.dpi.dest_ips.join(', ') : '';
          mapResult[this.$t('statistics.locationForDownload')] = client.locationName ? client.locationName : '';
          mapResult[this.$t('statistics.companyForDownload')] = client.companyName ? client.companyName : this.$t('general.no').toLowerCase();

          return mapResult;
        });
      } else {
        result = copy.map((client) => {
          const mapResult = {};
          mapResult[this.$t('statistics.macForDownload')] = client.mac ? this.getFormatMac(client.mac) : '';
          mapResult[this.$t('statistics.authorizationDataForDownload')] = client.username || '';
          mapResult[this.$t('statistics.cpeForDownload')] = client.cpe_id ? commonService.cpeNamebyId(client.cpe_id) : '';
          mapResult[this.$t('statistics.wlanForDownload')] = client.wlan_id || '';
          mapResult[this.$t('statistics.ssidForDownload')] = client.ssid || '';
          mapResult[this.$t('statistics.vlanForDownload')] = client.radio_id || '';
          mapResult[this.$t('statistics.startForDownload')] = client.start ? this.formatDateForCSV(client.start) : '';
          mapResult[this.$t('statistics.stopForDownload')] = client.stop ? this.formatDateForCSV(client.stop) : '';
          mapResult[this.$t('statistics.trafficDownloadedForDownload')] = this.formatBytes(client.rx_bytes);
          mapResult[this.$t('statistics.trafficUploadedForDownload')] = this.formatBytes(client.tx_bytes);
          mapResult[this.$t('statistics.ipsForDownload')] =
            client.dpi && client.dpi.dest_ips && Array.isArray(client.dpi.dest_ips) ? client.dpi.dest_ips.join(', ') : '';


          return mapResult;
        });
      }


      return result;
    },
    // formatRawClientsToExcel(data, graphName = 'data') {
    //   if (data.length === 0) {
    //     return { [graphName]: [] };
    //   }
    //   const result = [];
    //   data.forEach((row) => {
    //     // console.log(row)
    //     if (result.length === 0) {
    //       result.push([
    //         // 'MAC',
    //         // 'Authorization data',
    //         // 'CPE',
    //         // 'WLAN',
    //         // 'SSID',
    //         // 'VLAN',
    //         // 'Start',
    //         // 'Stop',
    //         // 'Traffic Downloaded',
    //         // 'Traffic Uploaded',
    //         // 'IPs',
    //         // 'Location',
    //         // 'Company'
    //         this.$t('statistics.macForDownload'),
    //         this.$t('statistics.authorizationDataForDownload'),
    //         this.$t('statistics.cpeForDownload'),
    //         this.$t('statistics.wlanForDownload'),
    //         this.$t('statistics.ssidForDownload'),
    //         this.$t('statistics.vlanForDownload'),
    //         this.$t('statistics.startForDownload'),
    //         this.$t('statistics.stopForDownload'),
    //         this.$t('statistics.trafficDownloadedForDownload'),
    //         this.$t('statistics.trafficUploadedForDownload'),
    //         this.$t('statistics.ipsForDownload'),
    //         this.$t('statistics.locationForDownload'),
    //         this.$t('statistics.companyForDownload')
    //       ]);
    //     }
    //
    //     let locationName = '';
    //     let companyNameForDownload = '';
    //
    //     const rowCpe = this.getCpeById(row.cpe_id);
    //     if (rowCpe !== undefined && rowCpe.hasOwnProperty('base_location')) {
    //       const baseLocation = rowCpe.base_location;
    //       // console.log(baseLocation)
    //       const location = this.getLocationById(baseLocation);
    //       locationName = location.name;
    //       // console.log(location);
    //       const companyName = this.locationPartyGetField(baseLocation, 'name') ? this.locationPartyGetField(baseLocation, 'name') : this.$t('general.no').toLowerCase() ;
    //       companyNameForDownload = companyName;
    //     }
    //
    //     result.push([
    //       row.mac ? this.getFormatMac(row.mac) : '',
    //       row.username || '',
    //       row.cpe_id ? commonService.cpeNamebyId(row.cpe_id) : '',
    //       row.wlan_id || '',
    //       row.ssid || '',
    //       row.radio_id || '',
    //       row.start ? this.formatDateForCSV(row.start) : '',
    //       row.stop ? this.formatDateForCSV(row.stop) : '',
    //       this.formatBytes(row.rx_bytes),
    //       this.formatBytes(row.tx_bytes),
    //       row.dpi && row.dpi.dest_ips && Array.isArray(row.dpi.dest_ips) ? row.dpi.dest_ips.join(', ') : '',
    //       locationName,
    //       companyNameForDownload
    //     ]);
    //   });
    //   return { [graphName]: result };
    // },

    formatRawClientsToExcel(data, graphName = 'data') {
      if (data.length === 0) {
        return {[graphName]: []};
      }
      const result = [];
      data.forEach((row) => {
        // console.log(row)
        if (result.length === 0) {
          if (this.areLocationsVisible) {
            result.push([
              this.$t('statistics.macForDownload'),
              this.$t('statistics.authorizationDataForDownload'),
              this.$t('statistics.cpeForDownload'),
              this.$t('statistics.wlanForDownload'),
              this.$t('statistics.ssidForDownload'),
              this.$t('statistics.vlanForDownload'),
              this.$t('statistics.startForDownload'),
              this.$t('statistics.stopForDownload'),
              this.$t('statistics.trafficDownloadedForDownload'),
              this.$t('statistics.trafficUploadedForDownload'),
              this.$t('statistics.ipsForDownload'),
              this.$t('statistics.locationForDownload'),
              this.$t('statistics.companyForDownload')
            ]);
          } else {
            result.push([
              this.$t('statistics.macForDownload'),
              this.$t('statistics.authorizationDataForDownload'),
              this.$t('statistics.cpeForDownload'),
              this.$t('statistics.wlanForDownload'),
              this.$t('statistics.ssidForDownload'),
              this.$t('statistics.vlanForDownload'),
              this.$t('statistics.startForDownload'),
              this.$t('statistics.stopForDownload'),
              this.$t('statistics.trafficDownloadedForDownload'),
              this.$t('statistics.trafficUploadedForDownload'),
              this.$t('statistics.ipsForDownload'),

            ]);
          }
        }

        let locationName = '';
        let companyNameForDownload = '';

        const rowCpe = this.getCpeById(row.cpe_id);
        if (rowCpe !== undefined && rowCpe.hasOwnProperty('base_location')) {
          const baseLocation = rowCpe.base_location;
          // console.log(baseLocation)
          const location = this.getLocationById(baseLocation);
          locationName = location.name;
          // console.log(location);
          const companyName = this.locationPartyGetField(baseLocation, 'name') ? this.locationPartyGetField(baseLocation, 'name') : this.$t('general.no').toLowerCase();
          companyNameForDownload = companyName;
        }

        if (this.areLocationsVisible) {
          result.push([
            row.mac ? this.getFormatMac(row.mac) : '',
            row.username || '',
            row.cpe_id ? commonService.cpeNamebyId(row.cpe_id) : '',
            row.wlan_id || '',
            row.ssid || '',
            row.radio_id || '',
            row.start ? this.formatDateForCSV(row.start) : '',
            row.stop ? this.formatDateForCSV(row.stop) : '',
            this.formatBytes(row.rx_bytes),
            this.formatBytes(row.tx_bytes),
            row.dpi && row.dpi.dest_ips && Array.isArray(row.dpi.dest_ips) ? row.dpi.dest_ips.join(', ') : '',
            locationName,
            companyNameForDownload
          ]);
        } else {
          result.push([
            row.mac ? this.getFormatMac(row.mac) : '',
            row.username || '',
            row.cpe_id ? commonService.cpeNamebyId(row.cpe_id) : '',
            row.wlan_id || '',
            row.ssid || '',
            row.radio_id || '',
            row.start ? this.formatDateForCSV(row.start) : '',
            row.stop ? this.formatDateForCSV(row.stop) : '',
            this.formatBytes(row.rx_bytes),
            this.formatBytes(row.tx_bytes),
            row.dpi && row.dpi.dest_ips && Array.isArray(row.dpi.dest_ips) ? row.dpi.dest_ips.join(', ') : '',
          ]);
        }


      });
      return {[graphName]: result};
    },



    // sessionToCsv(sessions) {
    //   if (!sessions) {
    //     return [];
    //   }
    //   const csvSessions = JSON.parse(JSON.stringify(sessions));
    //   csvSessions.forEach((session) => {
    //     if (!session.y) {
    //       session.y = 0;
    //     }
    //     // session.x = moment(+session.x).format(' D MMM YYYY, H:mm:ss ');
    //     session.x = moment(+session.x).format('DD.MM.YYYY, H:mm:ss');
    //   });
    //
    //   return csvSessions;
    // },
    // sessionToCsv(sessions) {
    //   if (!sessions) {
    //     return [];
    //   }
    //   const csvSessions = JSON.parse(JSON.stringify(sessions));
    //   const csvSessionsToResult = [];
    //   csvSessions.forEach((session) => {
    //     if (!session.y) {
    //       session.y = 0;
    //     }
    //     // session.x = moment(+session.x).format(' D MMM YYYY, H:mm:ss ');
    //     session.x = moment(+session.x).format('DD.MM.YYYY, H:mm:ss');
    //     csvSessionsToResult.push({ y: session.y, x: session.x });
    //   });
    //
    //   return csvSessionsToResult;
    // },
    sessionToCsv(sessions) {
      if (!sessions) {
        return [];
      }
      const csvSessions = JSON.parse(JSON.stringify(sessions));
      const csvSessionsToResult = [];
      csvSessions.forEach((session) => {
        if (!session.y) {
          session.y = 0;
        }
        // session.x = moment(+session.x).format(' D MMM YYYY, H:mm:ss ');
        session.x = moment(+session.x).format('DD.MM.YYYY, H:mm:ss');
        const tmpObj = {};
        tmpObj[this.$t('general.sessions')] = (session.y === 0) ? this.$t('general.disconnectedShort') : this.$t('general.connectedShort');
        tmpObj[this.$t('general.time')] = session.x;
        csvSessionsToResult.push(tmpObj);
      });

      return csvSessionsToResult;
    },
    txRxToCsv(rates) {
      if (!rates) {
        return [];
      }

      const ratesByTime = {};

      rates.series.forEach((series) => {
        const { name } = series;
        series.data.forEach((item) => {
          const time = item.x;
          const value = item.y;
          ratesByTime[time] = ratesByTime[time] || {};
          ratesByTime[time][name] = value;
        });
      });
      const result = [];

      Object.keys(ratesByTime).forEach((time) => {
        // ratesByTime[time].time = moment(+time).format(' D MMM YYYY, H:mm:ss ');
        // ratesByTime[time].time = moment(+time).format('DD.MM.YYYY, H:mm:ss');
        ratesByTime[time][this.$t('general.time')] = moment(+time).format('DD.MM.YYYY, H:mm:ss');
        result.push(ratesByTime[time]);
      });
      // console.log(result)
      return result;
    },
    getUsernames(client) {
      if (client.usernames === undefined) {
        return [];
      }
      return [...client.usernames];
    },
    getLast(arr) {
      return arr.length > 0 ? arr[arr.length - 1] : undefined;
    },
    getSortFieldName(field) {
      switch (field) {
        case 'rx_bytes':
          return this.$t('statistics.byRxTraffic');
        case 'tx_bytes':
          return this.$t('statistics.byTxTraffic');
        case 'duration':
          return this.$t('statistics.byDuration');
        default:
          return field;
      }
    },
    loadClientsRaw() {
      // statClientService.getClientsRaw(this);
      statClientService.getClientsRawWithLocationFilter(this);
    },
    closeRawModal() {
      this.showRawModal = false;
      this.$store.commit('setClientsRawList', false);
    },
    validatePagination: helpers.validatePagination,
    cpeNamebyId: commonService.cpeNamebyId,
    wlanNamebyId: commonService.wlanNamebyId
  },
  // created() {
  //   this.filters.location = this.userBaseLocation;
  //   this.$store.commit('setCpesList', false);
  //   this.getClientsSessions();
  //   this.$store.commit('setClientStatId', null);
  // },
  // created() {
  //   this.$store.commit('setCpesList', false);
  //   this.$store.commit('setClientStatId', null);
  //   this.$store.commit('setClientsSessionsList', false);
  //   this.$store.commit('setClientsRatingList', false);
  //   const hasLocalStorageDataForRestoring = this.checkComponentStateDataForRestore();
  //   const goBackFromClients = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromClients');
  //   const needRestoreState = hasLocalStorageDataForRestoring && goBackFromClients;
  //   if (needRestoreState) {
  //     this.loadComponentStateDataForRestoreAndRestore();
  //     this.removeComponentStateDataForRestore();
  //   }
  //   if (!needRestoreState && hasLocalStorageDataForRestoring) {
  //     this.removeComponentStateDataForRestore();
  //   }
  //
  //   if (!needRestoreState) {
  //     this.filters.location = this.userBaseLocation;
  //     this.getClientsSessions();
  //   }
  // },
  created() {
    let inHeaderLocationSelectedApplying = true;
    this.$store.commit('setCpesList', false);
    this.$store.commit('setClientStatId', null);
    this.$store.commit('setClientsSessionsList', false);
    this.$store.commit('setClientsRatingList', false);
    const hasLocalStorageDataForRestoring = this.checkComponentStateDataForRestore();
    const goBackFromClients = this.$route.hasOwnProperty('params') && this.$route.params.hasOwnProperty('goBackFromClients');
    const needRestoreState = hasLocalStorageDataForRestoring && goBackFromClients;
    if (needRestoreState) {
      this.loadComponentStateDataForRestoreAndRestore();
      this.removeComponentStateDataForRestore();
    }
    if (!needRestoreState && hasLocalStorageDataForRestoring) {
      this.removeComponentStateDataForRestore();
    }

    if (!needRestoreState) {
      this.filters.location = this.userBaseLocation;

      if (this.$store.state.inHeaderLocationSelectModeEnabled &&
        inHeaderLocationSelectedApplying) {
        let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
        let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
        if (!locationForFiltering && locationForFiltering === '') {
          locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
          this.locationInFiltersData = commonService.locationObjbyId(locationForFiltering)
          this.filters.location = locationForFiltering
        } else {
          this.filters.location = locationForFiltering
          this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        }

        // this.filters.location = locationForFiltering
        this.filters.with_childs = withChildsForFiltering;
      }

      this.getClientsSessions();
    }

  },
  watch: {
    locale() {
      this.changeCaptionsWithI18nOnCharts();
    },
    tableSearchValue(newQueryValue) {
      this.debounce(() => {
        this.filters.query = newQueryValue;
      }, 1000);
    }
  },
  mounted() {
    // подписка на обновление данных в интервале (если режим обновления активирован - vuex module intervalUpdateMode)
    this.subscribeOnIntervalUpdate(() => {
      if (this.statVisible) {
        this.responseStats();
      } else {
        this.getClientsSessions();
      }
    });
  },
  filters: {
    moment(value) {
      if (value) {
        return moment(value * 1000).format(' D MMM YYYY, H:mm ');
      }
    },
    momentnow(value, isRuLocale) {
      if (value) {
        if (isRuLocale) {
          return moment.duration(value, 'seconds').format('D дн., h ч., mm мин., ss сек.');
        }
        return moment.duration(value, 'seconds').format('D [day] h [h], mm [min], ss [sec]');
      }
    },
    toFixed(val, precission) {
      if (val && precission) {
        return val.toFixed(precission);
      }
    }
  }
};
</script>

<style lang="css" scoped>
.control-panel {
  display: flex;
  gap: 24px;
  padding: 8px 0;
}

.control-panel__container {
  display: flex;
  gap: 8px;
}

.control-panel__container.right {
  justify-content: flex-end;
  flex: 1 1 auto;
}
</style>

<style lang="scss">
  .datepicker-custom-height-for-statclients_27 {
  height: 27px !important;
}
      .input-group-for-location-select-in-statclients--filters {


    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;

  }

  .select-location-in-statclients--filters .select__list-container {
    width: 400px;
  }


.filter-buttons-in-statclients {
  margin-top: 100px !important;
}
.min-width-for-filters-in-statsclients {
  max-width: 250px;
}
.height-custom-for-filters-in-statclients-custom-padding {
  padding-top: 11px !important;
}
.filters-in-statclients {
  z-index: 9999 !important;
  min-width: 200px;
}
.height-custom-for-filters-in-statclients {
  height: 40px !important;
}
/*.multiselector-in-statclients {*/
/*  z-index: 999998 !important;*/
/*  !*min-width: 200px;*!*/
/*}*/
.multiselector-in-statclients {
  z-index: 999997 !important;
  /*min-width: 200px;*/
}
.location-filters-in-statsclients {
  min-width: 200px !important;
}
.clear-button-in-statclients {
  z-index: 9999 !important;
}
.clients {
  display: flex;
}

.clients-rating-list {
  width: 100%;
}

.client-stat {
  display: inline-block;
  width: 0;
  word-wrap: break-word;
}

.stat-visible .clients-rating-list {
  display: inline-block;
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 25%;
}

.stat-visible .client-stat {
  transition: width 0.2s;
  width: 75%;
}

.cpe-row {
  transition: background 0.15s;
  cursor: pointer;
}

.cpe-row.selected {
  background: #d9e3ec;
}

.cpe-row:hover {
  background: rgba(236, 236, 236, 0.59);
}

.cpe-row--sorted {
  background: rgba(236, 236, 236, 0.59);
}

.btn {
  cursor: pointer;
}

.table-row-move {
  transition: all 0.5s;
}
</style>

<style lang="scss" scoped>
.lables-in-statclients-filters {
  max-width: 100%;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}
.no-wrap-in-statistic-filters {
}
.client-name i {
  left: -15px;
}

.filters__smaller-button {
  max-height: 27px;
  padding: 0.25rem 1rem;
}

.filters__pagination {
  max-width: 150px;
}

.filters__user {
  max-width: 240px;
}

.filters__pagination .filters__input {
  min-width: unset;
}

.filters__label {
  width: 100%;
  margin-bottom: 4px;

  line-height: normal;

  opacity: 0.7;
}

.flex-wrap .clear-input {
  top: unset;
  bottom: 3px;
}

#rawDataModal tr:hover {
  background-color: unset;
  cursor: unset;
}

.smallDP {
  padding: 0.25rem 0.5rem;
}

.filters-date {
  overflow: hidden;
  align-self: center;

  font-size: 12px;
  white-space: nowrap;
  line-height: 1.2;
}

.loader-backdrop-statclients:after {
  position: absolute;
  top: 30px;
}
.switcher-with-childs-in-stat-clients label.switch {
  margin-bottom: 0 !important;
}
.switcher-with-childs-in-stat-clients {
  top: 5px !important;
  position: relative !important;
}
</style>
<style scoped>
.disabled-when-date-range-limit-exceeded-error {
  cursor: not-allowed !important;
}
.date-range-limit-exceeded-error-for-rating {
  position: absolute;
  left: 0;
  top: 100%;
}
.with-child {
  position: relative;
  font-size: 0.7em;
  padding: 0;
  margin: 0em;
  border: 0;
  top: -1px;
}
.with-child-wrapper {
  /*margin-left: 0.3em;*/
}
.switcher-with-childs-in-stat-clients label.switch {
  margin: 0 !important;
}
.switcher-with-childs-in-stat-clients label {
  height: 27px !important;
  margin: 0px !important;
}

.switcher-with-childs-in-stat-clients span.switch-handle,
.switcher-with-childs-in-stat-clients span.switch-label {
  height: 27px !important;
}
.switcher-with-childs-in-stat-clients-wrapper {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  margin-left: 5px !important;
  height: 27px !important;
}
.switcher-with-childs-in-stat-clients-caption {
  /*margin: 0px !important;*/
  margin-left: -12px !important;
  text-align: center;
  max-width: 50px;
}
.enable-filters-button {
  margin: 0px !important;
  margin-top: 10px !important;
  padding: 0 !important;
  border: 0 !important;
}
.flex-gap-1 {
  gap: 1em !important;
}
.last-row-filters-block-in-statclients {
  width: 80% !important;
}
.button-apply-last-row-filters-block-in-statclients {
  width: 20% !important;
}
</style>
